.image-slider {
    overflow: hidden;

    @media (max-width: 767px) {
        margin-bottom: 64px;
        padding-right: 24px;
    }
}

.image-slider__container {
    display: flex;

    @media (max-width: 980px) {
        gap: 32px;
    }
}

.image-slider__slide {
    flex: 0 0 100%;

    @media (max-width: 980px) {
        max-width: 264px;
    }

    img {
        display: block;
        border-radius: 10px 10px 10px 0;

        @media (max-width: 980px) {
            max-width: 100%;
            height: auto;
            border-radius: 10px;
        }
    }
}
