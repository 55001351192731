.header_v2 {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
    transition: transform 0.3s;
    background-color: var(--klothed-bgColor);

    .rightSlider {
        right: 0;
        left: auto;

        .rightSlider__overlay {
            left: 100%;
            right: auto;
        }

        @media (max-width: 980px) {
            width: 100vw;
            height: 100vh;
            overflow: auto;
        }

        .rightSlider_ {
            @media (max-width: 980px) {
                width: 100vw;
                height: 100vh;
                padding-top: 50px;
            }

            a {
                color: var(--klothed-textColor);
                text-decoration: none;
            }

            .insideAbout,
            .rightSliderOption {
                @media (max-width: 980px) {
                    font-size: 24px;
                }
            }

            .rightSliderOptionFirst {
                @media (max-width: 980px) {
                    margin-top: 0;
                }
            }
        }

        .closeRightSlider {
            left: auto;
            right: 4vw;
            top: 32px;

            @media (max-width: 980px) {
                left: auto;
                right: 24px;
                top: 24px;
            }
        }
    }

    .rightSliderLine {
        display: flex;
        flex-direction: column;
        cursor: pointer;
    }

    a:visited {
        color: var(--klothed-textColor);
    }

    .header__inner {
        display: flex;
        justify-content: space-between;
        padding: 24px 0;
        align-items: center;

        .search {
            margin-left: 36px;
        }
        .toggle-theme {
            display: flex;
            margin-right: 12px;
            cursor: pointer;
        }
        .status {
            margin-right: 16px;
            display: flex;
        }
        .status.missing,
        .status.rejected {
            svg > path {
                fill: var(--klothed-textColor-error);
            }
        }
        .status.in-review,
        .status.unknown {
            svg {
                display: none;
            }
            border: 2px solid var(--klothed-textColor); /* Blue */
            border-bottom: 2px solid var(--klothed-bgColor);
            border-radius: 50%;
            width: 18px;
            height: 18px;
            animation: spin 3s linear infinite;
        }
        @keyframes spin {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }
        .user {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;

            display: flex;
            align-items: center;
            text-align: right;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            color: var(--klothed-textColor);
            text-decoration: none;
            user-select: none;
            a {
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .name {
                color: var(--klothed-textColor);
            }
        }
    }

    .header__back {
        display: none;
        background: none;
        border: 0;
        border-radius: 0;

        @media (max-width: 980px) {
            display: block;
        }

        span {
            display: block;
            width: 7px;
            height: 7px;
            border: 3px solid var(--klothed-textColor);
            border-top-color: transparent;
            border-right-color: transparent;
            transform: rotate(45deg);
        }
    }

    .header__login {
        text-transform: uppercase;
        letter-spacing: 0.1em;
        font-family: "Commuters Sans";
        font-size: 24px;

        @media (max-width: 980px) {
            display: none;
        }

        a {
            color: var(--klothed-textColor);
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .logo {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        user-select: none;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        > a {
            display: flex;
        }
        img {
            width: auto;
            height: 24px;
        }
    }

    &.is-hidden {
        transform: translateY(-100%);
    }
}
