.tryOnsPage {
    display: flex;
    justify-content: center;
    margin-left: 15vw;
    margin-right: 15vw;
    margin-top: calc(6vh + 85px + 48px + 65px);

    .tryOnsPage_ {
        display: flex;
        flex-flow: wrap;
        justify-content: flex-start;

        .tryOnsItem {
            margin: 0px 17px 34px 17px;
            width: 290px;

            .tryOnsItemInside {
                border: 1px solid rgba(255, 255, 255, 0.5);
                border-radius: 6px;

                .tryOnsItemImage {
                    width: 286px;
                    height: 544px;
                    position: relative;

                    .tryOnsCustomrImage {
                        border-radius: 6px 6px 0px 0px;
                        height: 100%;
                        object-fit: cover;
                    }

                    .tryOnsCustomrImageOriginal {
                        opacity: 0;
                        position: absolute;
                    }

                    .tryOnsCustomrImageOriginal:hover {
                        opacity: 1;
                    }

                    .favoriteIcon_ {
                        z-index: 1;
                        position: relative;
                        bottom: 30px;
                        left: 16px;
                        cursor: pointer;
                    }
                }

                .productDetails {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    width: 256px;
                    height: 56px;
                    margin-left: 16px;
                    margin-right: 16px;

                    .productDetailsBrand {
                        font-family: "Commuters Sans";
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 16px;
                        letter-spacing: 0.1em;
                        color: var(--klothed-textColor);
                    }

                    .productDetailsType {
                        font-family: "Commuters Sans";
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 16px;
                        letter-spacing: 0.1em;
                        color: var(--klothed-textColor);
                    }
                }
            }

            .tryOnsItemInside:hover {
                border-color: #ff855f;
            }

            .itemViewGrp {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 288px;
                height: 48px;
                border: 1px solid rgba(255, 255, 255, 0.5);
                border-radius: 6px;
                margin-top: 8px;
                cursor: pointer;

                .itemViewText {
                    font-family: "Commuters Sans";
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 20px;
                    letter-spacing: 0.1em;
                    text-transform: uppercase;
                    color: #ff855f;
                    text-decoration: none;
                }
            }
        }
    }
}

.tryOnsBlackBox {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: calc(6vh + 84px);
    background-color: var(--klothed-bgColor);
    box-shadow: 24px 0px 24px 4px var(--klothed-bgColor);
    z-index: 1;
}

.retailerToggle {
    display: flex;
    flex-direction: row;
    position: fixed;
    top: calc(6vh + 85px);
    z-index: 2;

    .retailerToggle_wrapper {
        background: black;
        width: 160px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 8px 0px;
        border: 2px solid rgba(255, 255, 255, 0.5);

        .retailerToggle_btn {
            width: 156px;
            height: 28px;
            background-color: var(--klothed-bgColor);
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            font-family: "Commuters Sans";
            font-style: normal;
            font-weight: 400;
            font-size: 11px;
            line-height: 16px;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            color: var(--klothed-textColor);
            gap: 8px;
        }
    }

    .all_retailers_wrapper {
        border-radius: 6px 0px 0px 6px;
        border-right: none;

        .all_retailers {
            border-radius: 6px 0px 0px 6px;
        }
    }

    .my_retailers_wrapper {
        border-radius: 0px 6px 6px 0px;
        border-left: none;

        .my_retailers {
            border-radius: 0px 6px 6px 0px;
        }
    }

    .all_retailers_wrapper_selected {
        background: linear-gradient(90deg, #eaea15 2.23%, #ff855f 29.99%, #ff454e 51.12%, #0031ff 96.38%);
        border: none;
        height: 32px;
    }

    .my_retailers_wrapper_selected {
        background: linear-gradient(90deg, #eaea15 2.23%, #ff855f 29.99%, #ff454e 51.12%, #0031ff 96.38%);
        border: none;
        height: 32px;
    }
}
