.contexify_theme-klothed-menu {
    --contexify-menu-bgColor: var(--klothed-bgColor);
    --contexify-separator-color: #4c4c4c;
    --contexify-item-color: var(--klothed-textColor);
    --contexify-menu-radius: 0;
    --contexify-zIndex: 100001;
    --contexify-menu-shadow: none;
    --contexify-activeItem-bgColor: var(--klothed-textColor-trans20);
    --contexify-menu-padding: 0;
    height: 100%;
    width: 100%;
    margin: 72px auto auto auto;
    padding: 0 calc((100vw - 1310px + 64px + 14px) / 2);
    border: none;
    font-family: "Commuters Sans";
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.1em;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    flex-direction: column;
    .contexify_item,
    .contexify_theme-klothed-menu > a {
        margin-left: 24px;
        display: flex;
    }
    .title {
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 32px;
    }
    .disabled {
        cursor: not-allowed !important;
        pointer-events: none !important;
        color: var(--klothed-textColor-trans50);
    }
}

.contexify_theme-klothed-menu.grid {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;

    .title {
        flex: 100%;
    }
    .retailer {
        max-width: 24px;
        width: 24px;
        height: 24px;
        .icon {
            background-color: var(--klothed-textColor);
            width: 24px;
            height: 24px;
            img {
                width: 18px;
                height: 18px;
                margin: 3px;
                object-fit: scale-down;
            }
        }
    }
    .spacer {
        flex-grow: 1;
        flex: 100%;
    }
}

.contexify_theme-tile-menu {
    --contexify-menu-bgColor: rgba(0, 0, 0, 0);
    --contexify-separator-color: #4c4c4c;
    --contexify-item-color: var(--klothed-textColor);
    --contexify-menu-radius: 0;
    --contexify-zIndex: 100001;
    --contexify-menu-shadow: none;
    --contexify-activeItem-bgColor: var(--klothed-textColor-trans20);
    --contexify-menu-padding: 0;
    border: none;
    box-shadow: none;
    .contexify_item {
        background-color: var(--klothed-bgColor);
        border: 1px solid var(--klothed-textColor-dark70);
        border-radius: 0;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: "Commuters Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        margin-top: -1px;

        &:first-child {
            border-radius: 8px 8px 0 0 !important;
        }
        &:last-child {
            border-radius: 0 0 8px 8px !important;
        }
        .contexify_itemContent {
            color: var(--klothed-textColor);
            font-size: 11px;
            line-height: 16px;
            letter-spacing: 0.1em;
            font-family: "Commuters Sans";
            border-radius: 0 !important;
        }
    }
}
