.wrapper-line {
    max-width: 100%;
    color: var(--klothed-textColor);

    .wrapper-line__inner {
        position: relative;
        padding-top: 80px;

        &:after {
            content: "";
            position: absolute;
            left: -9px;
            top: 0;
            height: 200%;
            width: 1px;
            background-color: var(--klothed-textColor);
            opacity: 0.5;

            @media (max-width: 1200px) {
                left: -45px;
            }

            @media (max-width: 980px) {
                display: none;
            }
        }
    }

    &--right {
        .wrapper-line__inner {
            &:after {
                left: auto;
                right: 0;
            }
        }
    }
}
