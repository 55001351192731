.help_page_v2 {
    min-width: 1024px;
    display: flex;
    flex-direction: row;
    min-height: 100vh;

    @media (max-width: 1024px) {
        flex-wrap: wrap;
        min-width: 100%;
    }

    .klothed_logo {
        height: 54px;
        width: 182px;
        position: fixed;
        top: 4.5%;
        left: 5vw;
        cursor: pointer;
        z-index: 1;

        @media (max-width: 980px) {
            top: 25px;
            width: auto;
            max-height: 26px;
        }
    }

    .rightSlider {
        @media (max-width: 1024px) {
            position: fixed;
            overflow: auto;
        }
    }

    .rightSliderLine {
        display: flex;
        flex-direction: column;
        position: fixed;
        right: 5vw;
        width: calc(50% - 5vw);
        align-items: flex-end;
        height: 12.2%;
        justify-content: center;
        background-color: var(--klothed-bgColor);

        @media (max-width: 1024px) {
            background: none;
            height: 7%;
        }

        .rightSliderLine__ {
            display: flex;
            flex-direction: column;
            height: 28px;
            cursor: pointer;

            .rightSliderLine_ {
                width: 32px;
                height: 2px;
                background-color: var(--klothed-textColor);
                margin: 6px 0px;
            }
        }
    }

    .footer_section {
        position: fixed;
        left: 5vw;
        bottom: 3vh;
        font-family: "Commuters Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        color: var(--klothed-textColor);

        @media (max-width: 1024px) {
            position: static;
            padding: 64px 24px 32px;
        }
    }

    .leftTop_heading {
        font-family: "Commuters Sans";
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        text-align: right;
        text-transform: uppercase;
        color: var(--klothed-textColor);

        @media (max-width: 1024px) {
            display: none;
        }
    }

    .horizontal_separator_legal {
        height: 1px;
        background-color: var(--klothed-textColor-trans50);
        width: 100%;
        margin-top: 16px;

        @media (max-width: 1024px) {
            display: none;
        }
    }

    .rightSliderOverlay {
        position: fixed;
        top: 0px;
        left: 0px;
        min-height: 100vh;
        width: 100vw;
    }

    .help_left_section {
        width: 40vw;
        margin: 12vh 5vw 100px;
        letter-spacing: 0.1em;

        @media (max-width: 1024px) {
            width: auto;
            flex: 1 1 100%;
            margin: 0 24px;
        }

        .leftFixedSection {
            z-index: -1;

            @media (max-width: 1024px) {
                position: static;
            }

            .leftHeading {
                font-family: "Sometimes Times";
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 28px;
                letter-spacing: 0.1em;
                color: var(--klothed-textColor);
                margin-top: 116px;

                @media (max-width: 1024px) {
                    padding-top: 20px;
                    border-top: 1px solid var(--klothed-textColor);
                    margin-top: 90px;
                }
            }

            .leftHeadingTwo {
                font-family: "Commuters Sans";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.1em;
                color: var(--klothed-textColor);
                margin-top: 48px;
                width: 40vw;

                @media (max-width: 1024px) {
                    width: auto;
                }

                .orangeText {
                    color: #ff855f;
                    cursor: pointer;
                    text-decoration: none;
                }
            }
        }
    }

    .help_right_section {
        width: 40vw;
        margin: 12vh 5vw 100px;
        letter-spacing: 0.1em;

        @media (max-width: 1024px) {
            width: auto;
            flex: 1 1 100%;
            margin: 0 24px;
        }

        .fewFAQ {
            margin-top: 75px;
            font-family: "Sometimes Times";
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 28px;
            letter-spacing: 0.1em;
            color: var(--klothed-textColor);

            @media (max-width: 1024px) {
                margin-top: 30px;
            }
        }

        .qa_pairs {
            .qa_pair {
                margin-top: 48px;

                .pair_question {
                    font-family: "Sometimes Times";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 28px;
                    line-height: 40px;
                    letter-spacing: 0.1em;
                    color: var(--klothed-textColor);

                    .orangeText {
                        color: #ff855f;
                        cursor: pointer;
                        text-decoration: none;
                    }
                }

                .pair_answer {
                    font-family: "Commuters Sans";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    letter-spacing: 0.1em;
                    color: var(--klothed-textColor);
                    margin-top: 8px;

                    .orangeText {
                        color: #ff855f;
                        cursor: pointer;
                        text-decoration: none;
                    }
                }
            }
        }

        .point_one {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 48px;
            margin-bottom: 16px;

            .point_one_1 {
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid var(--klothed-textColor-trans50);
                border-radius: 6px;
                height: 48px;
                width: 48px;
                font-family: "Commuters Sans";
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                color: var(--klothed-textColor);
                margin-right: 16px;
            }

            .point_one_2 {
                font-family: "Commuters Sans";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.1em;
                color: var(--klothed-textColor);
                margin-right: 8px;
            }
        }

        .oneImg_ {
            width: 315px;
            height: 212px;
        }
    }
}
