.App {
    min-height: 100vh;
    max-width: 100vw;
    background: var(--klothed-bgColor);

    .klothed_logo {
        height: 54px;
        width: 182px;
        position: fixed;
        top: 4.5%;
        left: 5vw;
        cursor: pointer;
        z-index: 2;

        @media (max-width: 980px) {
            width: auto;
            max-height: 26px;
            top: 13px;
        }

        span {
            position: absolute;
            right: 0;
            top: 100%;
            transform: translateY(6px);
            color: var(--klothed-textColor);
            font-size: 11px;
            line-height: 16px;
            font-weight: normal;
            font-family: "Commuters Sans";

            @media (max-width: 980px) {
                display: none;
            }
        }
    }

    .rightSliderLine {
        display: flex;
        flex-direction: column;
        position: fixed;
        top: 4.5%;
        right: 5vw;
        cursor: pointer;
        z-index: 2;

        @media (max-width: 980px) {
            top: 11px;
        }

        .rightSliderLine_ {
            width: 32px;
            height: 2px;
            background-color: var(--klothed-textColor);
            margin: 6px 0px;
        }
    }

    .orangeText {
        color: #ff855f !important;
    }

    .rightSliderOverlay {
        position: fixed;
        top: 0px;
        left: 0px;
        min-height: 100vh;
        width: 100vw;
    }

    .grayed {
        opacity: 0.3;
    }
}

.rightSliderHeight {
    min-height: 100vh;
}
