.how-text {
    max-width: 100%;
    overflow: hidden;
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    color: var(--klothed-textColor);
    margin-bottom: 60px;

    @media (max-width: 980px) {
        border: 0;
    }

    .how-text__inner {
        display: flex;
        flex-direction: column;

        @media (max-width: 980px) {
            display: block;
        }
    }

    h5 {
        margin: 0;
        font-family: "Commuters Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 1.6;
        letter-spacing: 0.1em;

        @media (max-width: 980px) {
            font-size: 14px;
            line-height: 1.428571;
        }
    }

    h3 {
        margin: 0;
        font-size: 53px;
        line-height: 1.207547;
        font-family: "Sometimes Times";
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0.1em;

        @media (max-width: 980px) {
            font-size: 28px;
            line-height: 1.428571;
        }

        span {
            background: linear-gradient(90deg, #eaea15 2.23%, #ff855f 37.24%, #ff454e 64.39%, #be0edb 92.69%, #ad00ff 98.91%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            box-decoration-break: clone;
            -webkit-box-decoration-break: clone;
        }
    }

    .top-text {
        max-width: 47.95%;
        padding: 63px 3.6% 63px 0;
        border-right: 1px solid rgba(255, 255, 255, 0.5);

        @media (max-width: 980px) {
            max-width: 100%;
            margin-bottom: 60px;
            padding: 0;
            border: 0;
        }
    }

    .bottom-text {
        position: relative;
        padding: 5.35%;
        max-width: 37.55%;
        border: 1px solid rgba(255, 255, 255, 0.5);
        border-radius: 0px 6px 6px 6px;
        align-self: flex-end;

        @media (max-width: 980px) {
            max-width: 100%;
            padding: 10%;
        }

        &:before {
            content: "";
            position: absolute;
            width: 100vw;
            height: 1px;
            top: -1px;
            right: 100%;
            background-color: var(--klothed-textColor);
            opacity: 0.5;
        }
    }
}
