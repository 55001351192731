.heading-line {
    color: var(--klothed-textColor);
    overflow: hidden;
    max-width: 100%;
    margin-bottom: -20px;

    &.no-margin {
        margin-bottom: 0;
    }

    .heading-line__inner {
        position: relative;
        margin-left: 64px;
        display: grid;
        grid-template-columns: 1fr 575px;
        gap: 40px;

        @media (max-width: 980px) {
            margin-left: 0;
            grid-template-columns: 1fr 1fr;
        }

        @media (max-width: 767px) {
            grid-template-columns: 1fr;
        }
    }

    .no-ls {
        letter-spacing: 0;
        margin-right: -2px;
    }

    .heading-line__item {
        grid-column: 2 / 3;
        position: relative;

        @media (max-width: 980px) {
            grid-column: 1 / 2;
        }

        &:after {
            content: "";
            position: absolute;
            bottom: 20px;
            right: 100%;
            width: 100vw;
            height: 1px;
            background-color: var(--klothed-textColor);
            opacity: 0.5;
            transform: translateX(15px);

            @media (max-width: 980px) {
                display: none;
            }
        }
    }

    h2 {
        margin: 0;
        font-size: 40px;
        line-height: 1.6;
        font-family: "Commuters Sans";
        font-weight: normal;
        letter-spacing: 0.1em;

        @media (max-width: 767px) {
            font-size: 22px;
            line-height: 1.45;
        }

        span {
            position: relative;

            &:after {
                @media (max-width: 980px) {
                    content: "";
                    position: absolute;
                    bottom: 10px;
                    left: 100%;
                    height: 1px;
                    width: 100vw;
                    background-color: var(--klothed-textColor);
                    opacity: 0.5;
                }

                @media (max-width: 767px) {
                    bottom: 5px;
                }
            }
        }
    }

    &.heading-line--left {
        .heading-line__inner {
            margin: 0 0 -3px;
            grid-template-columns: 1fr 1fr;

            @media (max-width: 980px) {
                grid-template-columns: 1fr;
            }
        }

        .heading-line__item {
            grid-column: 1 / 2;

            &:after {
                content: "";
                position: absolute;
                bottom: 22px;
                left: 0;
                background-color: var(--klothed-textColor);
                opacity: 0.5;
                width: 100vw;
                height: 1px;
            }
        }

        h2 {
            span {
                position: relative;
                z-index: 1;
                background-color: var(--klothed-bgColor);

                &:after {
                    @media (max-width: 980px) {
                        bottom: 10px;
                    }

                    @media (max-width: 767px) {
                        bottom: 5px;
                    }
                }
            }
        }
    }

    &.heading-line--bottom {
        margin: 0;
        .heading-line__inner {
            position: relative;
            margin-left: 0;
            padding-bottom: 64px;
            overflow: hidden;

            @media (max-width: 980px) {
                overflow: visible;
            }
        }

        h2 {
            margin-left: -2px;
        }

        .heading-line__item {
            grid-column: 1 / 2;

            &:after {
                left: 0;
                top: calc(100% - 20px);
                width: 1px;
                height: 200%;
                transform: translateX(0);

                @media (max-width: 980px) {
                    width: 100vw;
                    height: 1px;
                    left: 100%;
                }
            }
        }
    }
}
