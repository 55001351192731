.user_forgot_password {
    display: flex;

    .artifact_group {
        max-width: calc(100% - 48px);
    }
    .forgotPassword_v2 {
        display: flex;
        flex-direction: column;
        letter-spacing: 0.1em;
        justify-content: space-around;
        align-items: center;
        gap: 24px;
        width: 100%;
        max-width: 400px;
        margin: 0 auto;
        flex-grow: 1;

        .email_enterDiv {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            height: 100%;
            width: 100%;

            .email_enterDivText {
                font-family: "Sometimes Times";
                font-weight: 400;
                font-size: 28px;
                letter-spacing: 0.1em;
                color: var(--klothed-textColor);
            }

            .email_input_div {
                position: relative;
                margin: 8px 0px;
                display: flex;
                width: 100%;

                .email_input_label {
                    position: absolute;
                    font-size: 1rem;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    background-color: var(--klothed-bgColor);
                    color: gray;
                    margin-left: 15px;
                    padding: 0px 5px;
                    transition: 0.2s ease-out;
                    transform-origin: left top;
                    pointer-events: none;
                    font-family: "Commuters Sans";
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    letter-spacing: 0.1em;
                    color: var(--klothed-textColor-trans70);
                }

                .email_input_box {
                    height: 48px;
                    width: 100%;
                    border: 1px solid var(--klothed-textColor-trans50);
                    border-radius: 6px;
                    outline: none;
                    font-family: "Commuters Sans";
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    letter-spacing: 0.1em;
                    color: var(--klothed-textColor);
                    background-color: var(--klothed-bgColor);
                    padding: unset;
                    padding-left: 20px;
                }

                .email_input_box:focus {
                    border-color: #ff855f;
                }

                .email_input_box:focus + .email_input_label {
                    color: #ff855f;
                    top: 0;
                    transform: translateY(-40%) translateX(-2%) scale(0.9);
                }

                .email_input_box:not(:placeholder-shown) + .email_input_label {
                    top: 0;
                    transform: translateY(-40%) translateX(-2%) scale(0.9);
                }
            }

            .email_enterDivSubmit {
                height: 48px;
                width: 100%;
                background: #ff855f;
                border-radius: 6px;
                font-family: "Commuters Sans";
                font-weight: 600;
                font-size: 14px;
                display: flex;
                justify-content: center;
                align-items: center;
                letter-spacing: 0.1em;
                text-transform: uppercase;
                color: var(--klothed-bgColor);
                pointer-events: none;
                opacity: 0.3;
            }

            .email_enterDivSubmitActive {
                pointer-events: initial;
                opacity: 1;
                cursor: pointer;
            }

            .email_enterDivTextLower {
                font-family: "Commuters Sans";
                font-weight: 400;
                font-size: 16px;
                letter-spacing: 0.1em;
                color: var(--klothed-textColor);
                white-space: break-spaces;
            }

            .invalidEmailEntered {
                font-family: "Commuters Sans";
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                letter-spacing: 0.1em;
                color: var(--klothed-textColor-error);
                max-width: 80vw;
            }
        }
    }
}
