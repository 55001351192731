.layout {
    overflow: hidden;
    margin: 95px auto 0 auto;

    @media (max-width: 767px) {
        padding-top: 80px;
    }

    &--spacing {
        padding-top: 190px;
    }

    p {
        margin-bottom: 20px;
    }

    .foot-logo {
        display: inline-block;
        transform: translateX(-8px);
    }

    .footer {
        color: var(--klothed-textColor);
        padding: 48px 0;
        @media (max-width: 1200px) {
            padding: 64px 0 32px;
        }

        p {
            font-size: 10px;
            font-family: "Commuters Sans";
            letter-spacing: 0.1em;
        }
    }
}
