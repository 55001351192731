.legal_section {
    display: flex;
    flex-direction: column;
    margin: 12vh 5vw 100px 0;
    letter-spacing: 0.1em;

    @media (max-width: 980px) {
        margin: 24px;
    }

    .leftTop_heading {
        font-family: "Commuters Sans";
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        text-align: right;
        text-transform: uppercase;
        color: var(--klothed-textColor);
    }

    .horizonta_seperator_legal {
        height: 1px;
        background-color: rgba(255, 255, 255, 0.5);
        width: 100%;
        margin-top: 16px;
    }

    .rightTop_heading {
        font-family: "Commuters Sans";
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: var(--klothed-textColor);
        text-transform: capitalize;
        margin-top: 75px;
    }

    .revisionDate_legal {
        margin-top: 30px;
        font-family: "Commuters Sans";
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: rgba(255, 255, 255, 0.5);
    }

    .legal_text {
        margin-top: 20px;
        font-family: "Commuters Sans";
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: var(--klothed-textColor);
        white-space: break-spaces;
    }

    .orangeText {
        color: #ff855f;
    }
}
