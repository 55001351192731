.welcome {
    gap: 18px;
    .image {
        user-select: none;
    }
    .klothed-desc {
        margin-top: 55px;
        display: flex;
        flex-direction: column;
        > h4 {
            font-family: "Commuters Sans";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.1em;
            text-align: left;
            margin: 24px 0;
        }

        .klothed-white {
            display: flex;
            flex-direction: column;
            position: relative;
            user-select: none;
            img {
                filter: var(--klothed-whiteImg-filter);
            }
            .tm {
                font-style: normal;
                font-weight: 400;
                font-size: 6px;
                line-height: 6px;
                position: absolute;
                right: 0;
            }
            > span {
                display: flex;
                justify-content: flex-end;
                font-weight: 600;
            }
        }
        > a {
            background: linear-gradient(90deg, #eaea15 2.23%, #ff855f 29.99%, #ff454e 51.12%, #0031ff 96.38%);
            border-radius: 6px;
            width: 100%;
            height: 50px;
            font-family: "Commuters Sans";
            font-weight: 600;
            font-size: 14px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-transform: uppercase;
            color: var(--klothed-tile-bgColor);
            cursor: pointer;
            user-select: none;
        }
    }
}
