.rightSliderAboutExp {
    min-height: max(1125px, 100vh) !important;
}

.rightSliderAboutExp_ {
    min-height: max(1125px, 100vh) !important;
}

.rightSlider {
    background: linear-gradient(90deg, #0031ff -15.87%, #ff454e 84.91%, #ff855f 99.38%);
    width: 40vw;
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 1;
    min-height: max(800px, 100vh);

    @media (max-width: 980px) {
        position: fixed;
        width: 100%;
        min-height: max(1000px, 100vh);
    }

    .rightSlider__overlay {
        position: absolute;
        top: 0;
        left: auto;
        right: 100%;
        height: 100%;
        width: 100vw;
        background-color: rgba(0, 0, 0, 0.7);
    }

    .hide-on-mobile {
        @media (max-width: 767px) {
            display: none;
        }
    }

    a {
        color: var(--klothed-textColor);
        text-decoration: none;
    }

    .rightSlider_ {
        background: linear-gradient(89.98deg, var(--klothed-bgColor) 3.77%, rgba(0, 0, 0, 0) 55.75%);
        width: 40vw;
        position: absolute;
        top: 0px;
        right: 0px;
        z-index: 2;
        display: flex;
        flex-direction: column;
        min-height: max(800px, 100vh);

        @media (max-width: 980px) {
            width: 100%;
            padding-top: 100px;
            min-height: 0;
            height: calc(100% - 100px);
        }

        .closeRightSlider {
            position: fixed;
            top: 4.5%;
            right: calc(5vw + 3px);
            cursor: pointer;

            @media (max-width: 980px) {
                top: 24px;
            }
        }

        .rightSliderOption {
            font-family: "Sometimes Times";
            font-style: normal;
            font-weight: 400;
            font-size: 28px;
            line-height: 40px;
            letter-spacing: 0.1em;
            color: var(--klothed-textColor);
            margin-bottom: 24px;
            padding-left: 4vw;
            width: fit-content;
            cursor: pointer;

            .getLogo_ {
                margin-left: 24px;
                vertical-align: middle;
            }

            .rotate180 {
                transform: rotateX(180deg);
            }
        }

        .underlineThis {
            margin-top: 5px;
            height: 1px;
            width: 152px;
            background-color: var(--klothed-textColor);
        }

        .insideAbout {
            font-family: "Sometimes Times";
            font-style: normal;
            font-weight: 400;
            font-size: 28px;
            line-height: 40px;
            letter-spacing: 0.1em;
            color: var(--klothed-textColor);
            margin-bottom: 24px;
            padding-left: 4vw;
            cursor: pointer;
        }

        .rightSliderOptionFirst {
            margin-top: calc(9% + 24px);
        }

        .insideAboutFirst {
            margin-top: 12px;
        }
    }

    .grayed {
        opacity: 0.3;
    }

    .side-text {
        font-size: 11px;
        letter-spacing: 0.1em;
        margin-left: 24px;
        font-family: "Commuters Sans";
    }
}
