.add_extension {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    top: 18vh;
    margin-left: 5vw;
    font-family: "Commuters Sans";
    color: var(--klothed-textColor);

    .add_extension_leftPane {
        .add_extension_Grp {
            opacity: 0.5;

            .add_extension_grp_NumAndText {
                display: flex;
                flex-direction: row;

                .add_extension_grp_Num {
                    font-weight: 600;
                    font-size: 24px;
                    display: flex;
                    border: 1px solid rgba(255, 255, 255, 0.5);
                    border-radius: 6px;
                    height: 56px;
                    width: 56px;
                    align-items: center;
                    justify-content: center;
                    margin-right: 24px;
                }

                .add_extension_grp_TextGrp {
                    display: flex;
                    flex-direction: column;

                    .add_extension_grp_Text {
                        font-weight: 400;
                        font-size: 16px;
                        letter-spacing: 0.1em;
                        height: 56px;
                        display: flex;
                        align-items: center;
                    }

                    .addExtension_NextBtn {
                        background: #ff855f;
                        border-radius: 6px;
                        font-weight: 600;
                        font-size: 14px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        letter-spacing: 0.1em;
                        text-transform: uppercase;
                        color: var(--klothed-bgColor);
                        width: 328px;
                        height: 48px;
                        cursor: pointer;
                    }

                    .addExtension_NextBtnColour {
                        background: linear-gradient(90deg, #eaea15 2.23%, #ff855f 29.99%, #ff454e 51.12%, #0031ff 96.38%);
                    }

                    .add_extension_Grp_Line {
                        margin-top: 16px;
                        margin-bottom: 32px;
                        height: 1px;
                        width: 495px;
                        background-color: rgba(255, 255, 255, 0.5);
                    }

                    .add_extension_LeftBottomText {
                        font-family: "Sometimes Times";
                        font-weight: 400;
                        font-size: 53px;
                        line-height: 64px;
                        letter-spacing: 0.1em;
                        white-space: break-spaces;

                        .text_italic {
                            font-style: italic;
                        }

                        .text_capitalize {
                            text-transform: capitalize;
                        }
                    }
                }
            }
        }

        .add_extension_Grp_Active {
            opacity: 1;
        }
    }

    .add_extension_rightPane {
        display: flex;
        flex-direction: column;
        margin-right: 10vw;
        align-items: flex-end;

        .addExtensionRightHeaderText {
            font-weight: 600;
            font-size: 16px;
            letter-spacing: 0.1em;
            text-transform: uppercase;
        }

        .addExtensionRightHeaderLine {
            margin-top: 16px;
            margin-bottom: 32px;
            height: 1px;
            width: 100%;
            background-color: rgba(255, 255, 255, 0.5);
        }
    }
}
