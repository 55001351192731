.try-on__share {
    display: flex;
    padding: 21px 0;
    gap: 48px;
    justify-content: center;

    @media (max-width: 767px) {
        position: absolute;
        gap: 34px;
        right: 24px;
        top: 85px;
        width: 20px;
        padding: 0;
        flex-direction: column;
        align-items: center;
    }

    svg {
        color: var(--klothed-tile-textColor);

        @media (max-width: 767px) {
            max-width: 100%;
        }

        path {
            @media (max-width: 767px) {
                color: var(--klothed-tile-textColor);
            }
        }
    }

    a {
        display: inline-flex;
        width: 32px;
        height: 32px;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        transition: background 0.3s;

        &:hover {
            background: rgba(0, 0, 0, 0.15);
        }

        &:active {
            background: rgba(0, 0, 0, 0.3);
        }
    }

    .tooltip {
        padding: 8px 16px;
        background-color: var(--klothed-bgColor);
        border-radius: 6px;
        color: var(--klothed-textColor);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        font-family: "Commuters Sans";
        font-weight: 400;
        font-size: 11px;
        line-height: 16px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
    }
}
