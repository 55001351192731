.team-row {
    margin: 64px 0;
    color: var(--klothed-textColor);

    @media (max-width: 767px) {
        margin: 40px 0;
    }

    &.last-section {
        @media (max-width: 980px) {
            margin-bottom: 60px;
        }
    }

    .team-row__inner {
        position: relative;
        display: grid;
        grid-template-columns: 48% 52%;
        align-items: center;
        border-radius: 6px;
        border: 1px solid rgba(255, 255, 255, 0.5);

        @media (max-width: 1200px) {
            grid-template-columns: 1fr;
            border: 0;
        }

        &:before {
            content: "";
            position: absolute;
            left: 48%;
            top: 0;
            height: 100%;
            width: 1px;
            background-color: var(--klothed-textColor);
            opacity: 0.5;

            @media (max-width: 1200px) {
                display: none;
            }
        }
    }

    h5 {
        margin: 0;
        font-size: 20px;
        line-height: 1.6;
        font-family: "Commuters Sans";
        font-weight: normal;
        letter-spacing: 0.1em;

        @media (max-width: 767px) {
            font-size: 14px;
            line-height: 1.428571;
        }
    }

    h3 {
        margin: 0;
        font-family: "Sometimes Times";
        font-style: normal;
        font-size: 53px;
        line-height: 1.207547;
        letter-spacing: 0.1em;
        font-weight: 300;

        @media (max-width: 767px) {
            font-size: 28px;
            line-height: 1.428571;
        }

        span {
            background: linear-gradient(90deg, #eaea15 2.23%, #ff855f 37.24%, #ff454e 64.39%, #be0edb 92.69%, #ad00ff 98.91%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            box-decoration-break: clone;
            -webkit-box-decoration-break: clone;
        }
    }

    .team-row__right,
    .team-row__left {
        padding: 48px;

        @media (max-width: 1300px) {
            padding: 20px;
        }

        @media (max-width: 1200px) {
            padding: 0;
            margin-top: 15px;
        }
    }

    .team-row__right {
        @media (max-width: 1200px) {
            grid-row: 1/2;
            margin-bottom: 0;
            padding-bottom: 20px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.5);
        }
    }
}
