.about-us-fun {
    .heading-line {
        margin-bottom: 64px;
    }

    .two-columns {
        @media (max-width: 980px) {
            margin-bottom: 60px;
        }

        &.mobie-mb-0 {
            margin-bottom: 0;
        }

        .img1 {
            border-radius: 6px 6px 0 6px;

            @media (max-width: 980px) {
                border-radius: 6px;
            }
        }

        .column--text {
            @media (max-width: 980px) {
                padding: 0;
                margin-bottom: 45px;
            }

            svg {
                @media (max-width: 980px) {
                    height: 24px;
                }
            }
        }

        h3 {
            @media (max-width: 980px) {
                font-size: 28px;
            }
        }

        h5 {
            @media (max-width: 980px) {
                font-size: 14px;
                line-height: 1.428571;
            }
        }
    }
}
