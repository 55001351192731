.login_and_signUp {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 100%;
    min-height: 100vh;
    align-items: center;
    padding: 10vh 0 0;
    overflow: hidden;
    justify-content: space-between;
    box-sizing: border-box;

    @media (max-width: 980px) {
        display: block;
    }

    .logIn_section {
        min-height: 0;
    }

    .left_pane {
        display: flex;
        flex: 0 0 50%;
        max-width: 50%;
        justify-content: center;
        align-items: center;

        @media (max-width: 980px) {
            display: block;
            padding: 0;
            width: auto;
            flex: 0 0 auto;
            max-width: 100%;
        }

        .artifect_group {
            position: relative;
            width: 100%;
            z-index: 0;
            padding-left: 30%;

            @media (max-width: 980px) {
                display: block;
                position: static;
                height: auto;
                width: auto;
                padding: 30px 24px 0;
                text-align: center;
            }

            .button {
                display: none;

                @media (max-width: 980px) {
                    display: flex;
                    margin: 0 auto;
                }
            }

            h4 {
                display: none;

                @media (max-width: 980px) {
                    display: block;
                    margin: 0 0 13px;
                    text-align: center;
                    font-size: 28px;
                    font-weight: normal;
                    font-family: "Sometimes Times";
                    color: var(--klothed-textColor);
                }
            }

            .image {
                position: relative;
                left: 50%;
                transform: translateX(-50%);
                max-width: 150%;
                @media (max-width: 980px) {
                    position: static;
                    transform: none;
                    margin: 0 -30% 0 -20%;
                    height: auto;
                }
            }

            .brandsSec {
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                align-items: center;

                @media (max-width: 980px) {
                    position: static;
                    display: block;
                    top: auto;
                    max-width: 328px;
                    margin: 0 auto;
                }

                .logo-wrapper {
                    @media (max-width: 980px) {
                        margin: 30px 0 0;
                    }
                }
            }
        }

        .backTo_Login_SignUp {
            display: flex;
            flex-direction: column;
            letter-spacing: 0.1em;
            position: fixed;
            margin-top: 10vh;
            top: 50%;
            transform: translateY(-50%);

            @media (max-width: 980px) {
                position: static;
                margin: 0;
                transform: none;
                align-items: center;
            }

            .signUp {
                background: linear-gradient(90deg, #eaea15 2.23%, #ff855f 29.99%, #ff454e 51.12%, #0031ff 96.38%);
                border-radius: 6px;
                width: 328px;
                height: 50px;
                font-family: "Commuters Sans";
                font-weight: 600;
                font-size: 14px;
                display: flex;
                align-items: center;
                justify-content: center;
                text-transform: uppercase;
                color: var(--klothed-textColor);
                margin-bottom: 24px;
                cursor: pointer;
            }

            .logIn_wrapper {
                background: linear-gradient(90deg, #eaea15 2.23%, #ff855f 29.99%, #ff454e 51.12%, #0031ff 96.38%);
                width: 328px;
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 8px 0px;
                border-radius: 6px;

                .logIn {
                    border-radius: 6px;
                    width: 324px;
                    height: 46px;
                    font-family: "Commuters Sans";
                    background-color: var(--klothed-bgColor);

                    font-weight: 600;
                    font-size: 14px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-transform: uppercase;
                    color: white;
                    cursor: pointer;
                }
            }
        }
    }

    .right_pane {
        position: relative;
        z-index: 1;
        flex: 0 0 50%;

        @media (max-width: 980px) {
            width: auto;
            min-height: 0;
        }
    }

    .footer_section {
        margin-top: auto;
        padding: 2vh 5vw 3vh;
        flex: 0 0 100%;
        font-family: "Commuters Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        color: var(--klothed-textColor);

        @media (max-width: 980px) {
            position: static;
            padding: 74px 24px 42px;
        }
    }
}
