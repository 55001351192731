.steps {
    color: var(--klothed-textColor);

    .steps__inner {
        display: grid;
        grid-template-columns: auto 66%;
        column-gap: 8px;
        align-items: flex-start;
    }

    h2 {
        margin: 0 0 24px;
        padding-bottom: 15px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);
        font-family: "Sometimes Times";
        font-weight: 400;
        font-size: 28px;
        line-height: 1.428571;
        letter-spacing: 0.1em;
    }

    h5 {
        position: relative;
        padding: 16px 0;
        margin: 0;
        font-family: "Commuters Sans";
        font-weight: 600;
        font-size: 12px;
        line-height: 1.333333;
        letter-spacing: 0.1em;
        color: var(--klothed-textColor);

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background: linear-gradient(90deg, #eaea15 2.23%, #ff855f 29.99%, #ff454e 51.12%, #0031ff 96.38%);
        }
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    li {
        position: relative;
        padding: 8px 0 16px;
        border-top: 1px solid rgba(255, 255, 255, 0.5);

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            padding: 0 8px;
            margin: 0 -8px;
            background: rgba(255, 255, 255, 0.2);
            border-radius: 6px;
            opacity: 0;
            transition: opacity 0.3s;
        }

        &:hover {
            &::before {
                opacity: 1;
            }
        }

        p {
            margin: 0;
            font-size: 12px;
            line-height: 1.333333;
            font-family: "Commuters Sans";
            letter-spacing: 0.1em;
            color: rgba(255, 255, 255, 0.7);
        }
    }

    .steps__col {
        &--image {
            position: relative;
            img {
                display: block;
                max-width: 100%;
                border-radius: 6px;
            }

            .image-not {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
}
