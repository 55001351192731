@keyframes slideOut {
    0% {
        left: -373px;
    }

    100% {
        left: 0px;
    }
}

@keyframes slideIn {
    0% {
        left: 0px;
    }

    100% {
        left: -373px;
    }
}

.createProfile_component {
    .createProfile_header {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 95px;
        right: 10vw;

        .createProfile_heading {
            font-family: "Commuters Sans";
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            color: var(--klothed-textColor);
            align-self: flex-end;
            margin-bottom: 16px;
        }

        .createProfile_steps {
            display: flex;
            flex-direction: row;

            .createProfile_steps_ {
                font-family: "Commuters Sans";
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                text-align: center;
                letter-spacing: 0.1em;
                color: rgba(255, 255, 255, 0.7);
                border: 1px solid rgba(255, 255, 255, 0.5);
                border-left: none;
                height: 40px;
                width: 190px;
                display: flex;
                justify-content: center;
                align-items: center;
                pointer-events: none;
            }

            .tutorialDone {
                pointer-events: initial;
                cursor: pointer;
            }

            .active_step {
                color: var(--klothed-textColor);
            }

            .createProfile_steps_:first-of-type {
                border-left: 1px solid rgba(255, 255, 255, 0.5);
                border-radius: 0px 0px 0px 6px;
            }

            .createProfile_steps_:last-of-type {
                border-radius: 0px 0px 6px 0px;
            }

            .gradient_border {
                display: none;
            }

            .upload_photo.active_step,
            .upload_photo.step_completed {
                border-top-color: black;

                .gradient_border {
                    display: block;
                    width: 192px;
                    height: 2px;
                    position: absolute;
                    top: 39px;
                    background: linear-gradient(90deg, #eaea15, #ff855f);
                    margin-left: 1px;
                }
            }

            .enter_height.active_step,
            .enter_height.step_completed {
                border-top-color: black;

                .gradient_border {
                    display: block;
                    width: 192px;
                    height: 2px;
                    position: absolute;
                    top: 39px;
                    background: linear-gradient(90deg, #ff855f, #ff454e);
                    margin-left: 1px;
                }
            }

            .select_style.active_step,
            .select_style.step_completed {
                border-top-color: black;

                .gradient_border {
                    display: block;
                    width: 192px;
                    height: 2px;
                    position: absolute;
                    top: 39px;
                    background: linear-gradient(90deg, #ff454e, #0031ff);
                    margin-left: 1px;
                }
            }
        }
    }

    .klothedBottom {
        position: fixed;
        bottom: 50px;
        left: 5%;
        font-family: "Commuters Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        color: var(--klothed-textColor);
    }
}
