.signUp_section {
    display: flex;
    flex-direction: column;
    letter-spacing: 0.1em;
    width: 328px;
    padding-left: 10vw;
    justify-content: center;

    @media (max-width: 980px) {
        width: auto;
        margin: 0 auto;
        padding: 20px 24px;
    }

    .errorMessage__ {
        height: 60px;
        padding-top: 36px;

        .errorMessageHeading {
            font-family: "Commuters Sans";
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.1em;
            color: var(--klothed-textColor-error);
        }
    }

    .signUp_top_header {
        font-family: "Sometimes Times";
        font-weight: 400;
        font-size: 28px;
        line-height: 40px;
        color: var(--klothed-textColor);
        white-space: break-spaces;
        margin-bottom: 16px;
    }

    .email_input_div,
    .password_input_div {
        position: relative;
        margin: 8px 0px;

        .email_input_label,
        .password_input_label {
            position: absolute;
            font-size: 1rem;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            background-color: var(--klothed-bgColor);
            color: gray;
            margin-left: 15px;
            padding: 0px 5px;
            transition: 0.2s ease-out;
            transform-origin: left top;
            pointer-events: none;
            font-family: "Commuters Sans";
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.1em;
            color: rgba(255, 255, 255, 0.7);
        }

        .email_input_box,
        .password_input_box {
            height: 48px;
            width: 306px;
            border: 1px solid rgba(255, 255, 255, 0.5);
            border-radius: 6px;
            outline: none;
            font-family: "Commuters Sans";
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.1em;
            color: var(--klothed-textColor);
            background-color: var(--klothed-bgColor);
            padding: unset;
            padding-left: 20px;

            @media (max-width: 980px) {
                width: 100%;
                box-sizing: border-box;
            }
        }

        .email_input_box_error,
        .password_input_box_error {
            border: 1px solid var(--klothed-textColor-error);
        }

        .email_input_label_error,
        .password_input_label_error {
            color: var(--klothed-textColor-error);
        }

        .password_input_box {
            font-family: unset;
            font-size: 22px;
        }

        .showPassword {
            font-family: "Commuters Sans";
            font-size: 16px;
        }

        .email_input_box:focus,
        .password_input_box:focus {
            border-color: #ff855f;
        }

        .email_input_box:focus + .email_input_label,
        .password_input_box:focus + .password_input_label {
            color: #ff855f;
            top: 0;
            transform: translateY(-40%) translateX(-2%) scale(0.9);
        }

        .email_input_box:not(:placeholder-shown) + .email_input_label,
        .password_input_box:not(:placeholder-shown) + .password_input_label {
            top: 0;
            transform: translateY(-40%) translateX(-2%) scale(0.9);
        }

        .showPasswordIMG {
            position: absolute;
            right: 10px;
            bottom: 17px;
            cursor: pointer;
        }
    }

    .signUp_button {
        background: linear-gradient(90deg, #eaea15 2.23%, #ff855f 29.99%, #ff454e 51.12%, #0031ff 96.38%);
        border-radius: 6px;
        width: 328px;
        height: 50px;
        font-family: "Commuters Sans";
        font-weight: 600;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        color: var(--klothed-textColor);
        margin: 8px 0px 16px;
        z-index: 1;
        opacity: 0.3;
        pointer-events: none;
    }

    .signUp_button_active {
        opacity: 1;
        pointer-events: initial;
        cursor: pointer;
    }

    .legal_acceptance {
        font-family: "Commuters Sans";
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.1em;
        color: var(--klothed-textColor);
        margin: 8px 0px;
    }

    .legal_acceptance_orange {
        color: #ff855f;
        cursor: pointer;
        z-index: 1;
    }

    .alternate_login_options {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin: 8px 0px;
        gap: 16px;

        .alternate_signUp {
            font-family: "Commuters Sans";
            font-weight: 400;
            font-size: 16px;
            color: rgba(255, 255, 255);
            align-self: center;
        }

        .alternate_login_options_CTA {
            z-index: 1;
            cursor: pointer;

            &.apple-login {
                display: none;

                @media (max-width: 980px) {
                    display: inline;
                }
            }
        }
    }

    .horizontal_separator {
        height: 1px;
        background-color: rgba(255, 255, 255, 0.5);
        width: 100%;
        margin: 16px 0px 16px;
    }

    .horizontal_separator_error {
        height: 2px;
        background-color: rgba(239, 19, 32, 0.2);
        margin: 8px 0px 16px;
    }

    .already_account {
        font-family: "Commuters Sans";
        font-weight: 400;
        font-size: 12px;
        color: var(--klothed-textColor);
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        .login_button {
            z-index: 1;
            width: 64px;
            height: 32px;
            font-family: "Commuters Sans";
            font-weight: 600;
            font-size: 11px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-transform: uppercase;
            cursor: pointer;
            border: 1px solid rgba(255, 255, 255, 0.5);
            border-radius: 6px;
            margin-left: 16px;
            background: linear-gradient(90deg, #eaea15 2.23%, #ff855f 37.24%, #ff454e 64.39%, #be0edb 80.69%, #ad00ff 84.91%);
            background-clip: text;
            text-fill-color: transparent;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }

    .store_CTA {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-family: "Commuters Sans";
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.1em;
        color: var(--klothed-textColor);

        a {
            text-decoration: none;
        }

        .selector {
            display: flex;
            flex-direction: column;

            .getApp {
                margin-bottom: 18px;
                font-size: 11px;
                color: var(--klothed-textColor);
                text-transform: uppercase;
            }

            .appleAppStore {
                cursor: pointer;
                z-index: 1;
            }

            .extensionLink {
                cursor: pointer;
                display: flex;
                flex-direction: row;
                width: 189px;
                height: 38px;
                background: #f5f5f5;
                border: 1px solid #727272;
                border-radius: 9px;

                .chromeLogo {
                    height: 24px;
                    width: 24px;
                    margin-left: 12px;
                    margin-right: 8px;
                    align-self: center;
                }

                .extensionText {
                    display: flex;
                    flex-direction: column;
                    color: var(--klothed-bgColor);
                    justify-content: space-evenly;

                    .extensionTextTop {
                        font-size: 10px;
                    }

                    .extensionTextBottom {
                        font-size: 14px;
                        letter-spacing: 0.01em;
                    }
                }
            }
        }
    }
}
