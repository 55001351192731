@media screen and (orientation: portrait) {
    :root {
        --klothed-tile-width: 144px;
        --klothed-tile-height: 273px;
    }
}

@media screen and (orientation: landscape) {
    :root {
        --klothed-tile-width: 156px;
        --klothed-tile-height: 296px;
    }
}
