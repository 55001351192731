.home_page {
    min-width: 1024px;
    .homePage_header {
        position: fixed;
        right: 5vw;
        top: 6vh;
        width: 45vw;
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);
        z-index: 3;

        .homePage_header_ {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .homePage_header_imgDiv {
                height: 60px;
                display: flex;
                align-items: center;
                cursor: pointer;
            }

            .homePage_header_text {
                font-family: "Commuters Sans";
                font-weight: 600;
                font-size: 16px;
                letter-spacing: 0.1em;
                text-transform: uppercase;
                color: var(--klothed-textColor);
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .homePage_header_left {
            width: 35%;
        }

        .homePage_header_verticalSeperator {
            width: 10%;
            display: flex;
            align-items: flex-end;
            justify-content: center;

            .homePage_header_verticalSeperatorLine {
                width: 1px;
                height: 45px;
                background-color: rgba(255, 255, 255, 0.5);
            }
        }

        .homePage_header_right {
            width: 55%;
        }
    }
}
