.try-on-wrapper {
    position: relative;
    display: flex;
    flex-grow: 1;
    width: 100vw;

    .try-on__item-image {
        position: relative;
        display: flex;
        @media (max-width: 767px) {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            z-index: -1;
            width: 100%;
            height: 100%;
        }

        img {
            @media (max-width: 767px) {
                position: absolute;
                width: 100%;
                height: 100%;
                object-fit: scale-down;
            }
        }

        a {
            display: block;

            .try-hover {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                object-fit: cover;
                opacity: 0;
                transition: opacity 0.3s;
            }

            @media (hover: hover) {
                &:hover {
                    .try-hover {
                        opacity: 1;
                    }
                }
            }
        }
    }

    .mobile-share {
        display: none;

        @media (max-width: 767px) {
            display: flex;
            position: fixed;
            width: 32px;
            height: 32px;
            right: 78px;
            top: 15px;
            align-items: center;
            justify-content: center;
        }

        button {
            background: none;
            border: 0;
            transform: scale(1.3);
        }
    }

    .try-on {
        position: fixed;
        height: 100%;
        width: 100%;
        margin: auto auto 0 auto;
        background-color: var(--klothed-textColor);
        border-radius: 6px;

        @media (max-width: 767px) {
            display: flex;
            flex-direction: column;
            background-color: transparent;
            justify-content: flex-end;
            border-radius: 0;
            color: var(--klothed-tile-bgColor);
        }

        .mobile-only {
            display: none;

            @media (max-width: 767px) {
                display: block;
            }

            &.mt-20 {
                @media (max-width: 767px) {
                    margin-top: 20px;
                }
            }

            .button {
                margin: 0;
                border-radius: 0 0 6px 6px;
            }

            h5 {
                text-align: center;
                font-family: "Commuters Sans";
                font-weight: 400;
                font-size: 11px;
                line-height: 16px;
                letter-spacing: 0.1em;
                text-transform: uppercase;
                color: var(--klothed-textColor);
            }
        }

        .try-on__inner {
            display: grid;
            grid-template-columns: auto 375px;

            @media (max-width: 767px) {
                padding: 26px 24px 36px 24px;
                margin-bottom: 41px;
                grid-template-columns: 1fr;
                background: rgba(0, 0, 0, 0.6);
            }
        }

        .try-on__item--flex {
            display: flex;
            padding-bottom: 40px;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;

            @media (max-width: 767px) {
                padding: 0;
            }
        }

        .try-on__item {
            h5 {
                margin: 0;
            }

            img {
                display: block;
                max-width: 100%;
                height: auto;
            }

            &:nth-child(3),
            &:nth-child(1) {
                border-right: 1px solid rgba(0, 0, 0, 0.5);

                @media (max-width: 767px) {
                    border: 0;
                }
            }

            &:nth-child(1),
            &:nth-child(2) {
                border-bottom: 1px solid rgba(0, 0, 0, 0.5);

                @media (max-width: 767px) {
                    border: 0;
                }
            }

            &:nth-child(1) {
                h5 {
                    font-family: "Commuters Sans";
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 16px;
                    letter-spacing: 0.1em;
                    text-transform: uppercase;

                    @media (max-width: 767px) {
                        font-size: 11px;
                    }
                }
            }
        }

        .try-on__item--share {
            @media (max-width: 767px) {
                display: none;
            }
        }

        .try-on__item--copy {
            padding: 28px;

            @media (max-width: 767px) {
                position: absolute;
                bottom: 0;
                left: 0;
                padding: 24px;
                z-index: 2;
                color: var(--klothed-textColor);
            }

            .copy {
                margin: 0;
                font-family: "Commuters Sans";
                font-weight: 400;
                font-size: 8px;
                line-height: 10px;
                text-align: right;
                letter-spacing: 0.1em;

                @media (max-width: 767px) {
                    font-size: 10px;
                }
            }
        }

        .try-on__button {
            @media (max-width: 767px) {
                display: none;
            }

            h5 {
                margin-bottom: 30px;
            }
        }

        .try-on__button,
        .try-on__text {
            padding: 60px 0;

            @media (max-width: 767px) {
                padding: 0;
            }

            img {
                max-width: 136px;
                height: auto;
            }
        }

        .try-on__text-logo {
            position: relative;
            height: 70px;
            margin-bottom: 18px;

            @media (max-width: 767px) {
                height: 26px;
            }

            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
}

.try-on-wrapper.desktop {
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    .try-on {
        width: 890px;
        height: fit-content;
        position: unset;
        background-color: var(--klothed-tile-bgColor);
        margin: 0;
        .try-on__inner {
            display: flex;
            .try-on__item-left {
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                align-items: center;
                justify-content: center;
                .try-on__block {
                    display: flex;
                    flex-direction: column;
                    flex-grow: 1;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 100%;
                    h5 {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
                }
                .try-on__item {
                    margin-top: auto;
                    width: 100%;
                    border-bottom: none;
                    height: 68px;
                }
            }
            .try-on__button,
            .try-on__text {
                padding: 0 0 60px 0;
                color: var(--klothed-tile-textColor);
            }
            .try-on__item-image-holder {
                width: fit-content;
                .try-on__item-image {
                    width: fit-content;
                    > img {
                        max-height: calc(100vh - 291px);
                        border-radius: 0 6px 0 0;
                    }
                    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
                }
                > .try-on__item-text-holder {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    width: 100%;
                    > .try-on__image-text {
                        margin: 8px 24px;
                        height: 52px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 6px;
                        background: linear-gradient(90deg, #eaea15 2.23%, #ff855f 29.99%, #ff454e 51.12%, #0031ff 96.38%);

                        .try-on__image-text__inner {
                            width: calc(100% - 2px);
                            height: calc(100% - 2px);
                            .try-on__col:nth-child(1) {
                                height: 100%;
                                > img {
                                    border-radius: 6px 0 0 6px;
                                }
                            }
                            &:first-child {
                                border-radius: 6px;
                            }
                        }
                    }
                }
            }
        }
    }
}
