.line-image {
    margin-top: 135px;
    margin-bottom: 60px;

    @media (max-width: 1200px) {
        margin-top: 0;
    }

    .line-image__inner {
        position: relative;
        padding: 0 64px;
        margin: 0 -7px;

        @media (max-width: 767px) {
            position: relative;
            display: flex;
            justify-content: flex-end;
            padding: 0;
            margin: 0 30px 0 0;
        }

        img {
            border-radius: 0 0 6px 6px;

            @media (max-width: 767px) {
                border-radius: 6px 6px 0 6px;
            }
        }

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100vw;
            height: 1px;
            opacity: 0.5;
            background-color: var(--klothed-textColor);

            @media (max-width: 767px) {
                left: 100%;
                top: auto;
                bottom: 0;
            }
        }
    }
}
