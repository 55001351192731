.team-row__image {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 480px;

    @media (max-width: 767px) {
        width: 140px;
        height: 220px;
    }

    img {
        @media (max-width: 767px) {
            max-width: 200%;
            height: auto;
        }
    }

    .img2,
    .img3 {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
    }

    &.is-animated {
        .img2 {
            animation: img1Animation infinite 3.5s;
        }
        .img3 {
            animation: img2Animation infinite 3.5s;
        }
    }
}

@keyframes img1Animation {
    0%,
    13% {
        opacity: 0;
        transform: translate(-50%, -50%);
    }

    20% {
        opacity: 1;
    }

    27%,
    90% {
        opacity: 1;
        transform: translate(-10%, -50%);
    }

    100% {
        opacity: 0;
        transform: translate(-10%, -50%);
    }
}

@keyframes img2Animation {
    0%,
    27% {
        opacity: 0;
        transform: translate(-50%, -50%);
    }

    40% {
        opacity: 1;
    }

    51%,
    85% {
        opacity: 1;
        transform: translate(15%, -50%);
    }

    95% {
        opacity: 0;
        transform: translate(15%, -50%);
    }
}
