// div.layout {
//     overflow: unset;
// }

.retailers {
    div.retailers_section_left {
        width: 50vw;
        background-color: var(--klothed-bgColor);
        box-shadow: 24px 0px 24px 4px var(--klothed-bgColor);

        .klothed_logo {
            height: 54px;
            width: 182px;
            position: fixed;
            top: 4.5%;
            left: 5vw;
            cursor: pointer;
            z-index: 1;

            @media (max-width: 980px) {
                width: auto;
                max-height: 26px;
            }
        }
    }

    .retailers_user > .retailers_section_left {
        position: relative;
    }

    .retailers_section {
        display: flex;
        flex-direction: row;

        .retailers_section_left {
            margin-left: 5vw;
            width: 45vw;
            margin-top: calc(6vh + 85px + 32px + 48px + 24px + 24px + 76px);

            .retailersAlphaGrp {
                width: calc(100% - 240px);
                padding: 40px;
                border-radius: 6px;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                margin-bottom: 28px;
                gap: 32px;

                .alphaBold {
                    font-family: "Sometimes Times";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 72px;
                    line-height: 64px;
                    letter-spacing: 0.1em;
                    color: var(--klothed-textColor);
                }

                .retailerGrp {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .favoriteIcon_Div {
                        height: 32px;
                        width: 32px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                    }

                    .retailerAnchor {
                        margin-left: 16px;
                        font-family: "Commuters Sans";
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                        letter-spacing: 0.1em;
                        color: var(--klothed-textColor);
                        text-decoration: none;
                    }

                    .grayed {
                        pointer-events: none;
                    }

                    .coming-soon {
                        margin-left: 24px;
                        color: var(--klothed-textColor);
                        font-size: 11px;
                        font-family: "Commuters Sans";
                    }

                    .retailerAnchor:hover {
                        color: #ff855f;
                    }
                }
            }
        }

        .retailers_section_right {
            margin-right: 5vw;
            width: 45vw;
            height: 100px;
            position: relative;
            top: calc(6vh + 85px + 32px + 48px + 24px + 24px + 76px);

            .retailerRightHeading {
                font-family: "Sometimes Times";
                font-style: normal;
                font-weight: 400;
                font-size: 53px;
                line-height: 64px;
                letter-spacing: 0.1em;
                color: var(--klothed-textColor);
                position: fixed;
            }
        }
    }

    .alphabetList {
        display: flex;
        flex-direction: row;
        position: fixed;
        top: calc(6vh + 85px + 32px + 48px);
        width: 45vw;
        justify-content: space-between;

        .alphabetList_ {
            font-family: "Commuters Sans";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.165em;
            color: var(--klothed-textColor);
            cursor: pointer;
        }
    }

    .horizontalSep__ {
        height: 1px;
        position: fixed;
        top: calc(6vh + 85px + 32px + 48px + 24px + 24px);
        width: 45vw;
        background-color: var(--klothed-textColor-trans50);
    }

    .retailerToggle {
        display: flex;
        flex-direction: row;
        position: fixed;
        top: calc(6vh + 85px);

        .retailerToggle_wrapper {
            background: var(--klothed-bgColor);
            width: calc(var(--klothed-tile-width) + 4);
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 8px 0px;
            border: 2px solid var(--klothed-textColor-trans50);
            user-select: none;
            .retailerToggle_btn {
                width: var(--klothed-tile-width);
                height: 28px;
                background-color: var(--klothed-bgColor);
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                font-family: "Commuters Sans";
                font-style: normal;
                font-weight: 400;
                font-size: 11px;
                line-height: 16px;
                letter-spacing: 0.1em;
                text-transform: uppercase;
                color: var(--klothed-textColor);
                gap: 8px;
            }
        }

        .all_retailers_wrapper {
            border-radius: 6px 0px 0px 6px;
            border-right: none;

            .all_retailers {
                border-radius: 6px 0px 0px 6px;
            }
        }

        .my_retailers_wrapper {
            border-radius: 0px 6px 6px 0px;
            border-left: none;

            .my_retailers {
                border-radius: 0px 6px 6px 0px;
            }
        }

        .all_retailers_wrapper_selected {
            background: linear-gradient(90deg, #eaea15 2.23%, #ff855f 29.99%, #ff454e 51.12%, #0031ff 96.38%);
            border: none;
            height: 32px;
        }

        .my_retailers_wrapper_selected {
            background: linear-gradient(90deg, #eaea15 2.23%, #ff855f 29.99%, #ff454e 51.12%, #0031ff 96.38%);
            border: none;
            height: 32px;
        }
    }
}
