.try-on__image-text {
    display: block;
    margin: 0 24px;
    padding: 1px;
    border-radius: 6px 6px 0 0;
    background: rgba(255, 255, 255, 0.5);
    color: var(--klothed-textColor);
    text-decoration: none;

    @media (max-width: 767px) {
        margin: 0 auto;
        max-width: 328px;
    }

    .try-on__image-text__inner {
        display: flex;
        align-items: center;
        background-color: var(--klothed-bgColor);
        justify-content: space-around;
        border-radius: 6px 6px 0 0;
        height: 48px;
    }

    &--highlight {
        border-radius: 6px;
        background: linear-gradient(90deg, #eaea15 2.23%, #ff855f 37.24%, #ff454e 64.39%, #be0edb 92.69%, #ad00ff 98.91%);

        .try-on__image-text__inner {
            border-radius: 6px;
        }
    }

    img {
        border-radius: 6px 0 0 0;
        filter: var(--klothed-blackImg-filter);
    }

    .try-on__col {
        &:nth-child(2) {
            flex-grow: 1;
            margin-left: 12px;
            // border-right: 1px solid rgba(255, 255, 255, 0.5);
        }
    }

    h5 {
        margin-bottom: 5px;
        font-family: "Commuters Sans";
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
    }

    p {
        margin: 0;
        font-family: "Commuters Sans";
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        letter-spacing: 0.1em;
        text-transform: capitalize;
    }
}
