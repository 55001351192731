.user_profile_photo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    .artifact_group {
        display: flex;
        align-items: center;
        justify-content: center;
        width: fit-content;
    }

    .profile_photo_section_v2 {
        display: flex;
        flex-direction: column;
        letter-spacing: 0.1em;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 24px;
        max-width: 375px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;

        h1 {
            font-family: "Commuters Sans";
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 28px;
            letter-spacing: 0.1em;
        }

        form#photo-upload {
            display: flex;
            flex-direction: column;
            border-radius: 10px;
            margin: 0 auto;
            .profile_photo_wrapper {
                position: relative;
                cursor: pointer;
                aspect-ratio: 375 / 500;
                width: clamp(200px, 80vw, 400px);

                .photo {
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    object-fit: scale-down;
                    position: absolute;
                    background-color: var(--klothed-tile-bgColor);
                }

                .clear {
                    position: absolute;
                    top: 16px;
                    right: 16px;
                    cursor: pointer;
                    z-index: 1;
                }
                .hide {
                    display: none;
                }

                .dropzone_wrapper,
                .dropzone {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    top: 0;
                    left: 0;
                    position: absolute;
                    color: var(--klothed-tile-textColor);
                    background-color: var(--klothed-tile-bgColor);
                    opacity: 0;
                    > span,
                    p {
                        margin: 12px 24px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        text-align: center;
                    }
                }

                .overlay {
                    opacity: 1;
                }
            }

            input[type="submit"],
            .current {
                width: 100%;
                height: 48px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-family: "Commuters Sans";
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                text-transform: uppercase;
                color: var(--klothed-bgColor);
                border-radius: 6px;

                cursor: pointer;
                border: none;
                background-color: var(--klothed-textColor);
                margin-top: 24px;

                &:disabled {
                    color: var(--klothed-textColor-trans50);
                    cursor: not-allowed;
                }
            }

            .buttons {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                gap: 12px;
                border-radius: 0 0 12px 12px;
                border: 2px var(--klothed-textColor-trans50) solid;
                border-top: none;

                input[type="submit"],
                .current {
                    width: 100%;
                    height: 48px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-family: "Commuters Sans";
                    font-weight: 600;
                    font-size: 14px;
                    letter-spacing: 0.1em;
                    text-transform: uppercase;
                    color: var(--klothed-textColor);
                    cursor: pointer;
                    border: none;
                    background-color: transparent;
                }
                .current {
                    border-radius: 0 0 0 8px;
                }
                .current.unknown,
                .current.missing,
                .current.rejected {
                    background-color: var(--klothed-textColor-error);
                }
                .current.approved {
                    background-color: rgba(0, 255, 0, 0.5);
                }
                .current.in-review {
                    background-color: rgba(0, 0, 255, 0.5);
                }
                input:disabled {
                    color: var(--klothed-textColor-trans50);
                    cursor: not-allowed;
                }
            }
            div.status {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                text-align: center;
                h2 {
                    font-weight: 400;
                    font-family: "Roboto";
                    font-style: normal;
                    font-size: 12px;
                    line-height: 14px;
                    margin-top: 24px;
                    margin-bottom: 28px;
                }
                .underline-text {
                    display: flex;
                    flex-direction: column;
                    gap: 4px;
                    font-family: "Roboto";
                    font-style: normal;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 14px;
                    cursor: pointer;
                    user-select: none;
                }
                span {
                    font-family: "Roboto";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 16px;
                }

                &.rejected {
                    h2 {
                        color: var(--klothed-textColor-error);
                    }
                }
                &.in-review > h2 {
                    font-style: italic;
                }
            }
        }
        .tips-section {
            display: flex;
            flex-direction: column;
            width: 100%;
            h2 {
                font-family: "Commuters Sans";
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 28px;
                letter-spacing: 0.1em;
                margin: 24px 0;
                padding: 0;
            }
            p {
                font-family: "Commuters Sans";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.1em;
                margin: 24px 0;
                padding: 0;
            }
            img {
                width: 100%;
                max-width: 375px;
                height: auto;
                margin: auto;
                display: flex;
            }
            .crossed {
                display: flex;
                position: relative;
                > div {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
}
