.about-us {
    @media (max-width: 767px) {
        padding-top: 126px;
    }

    .two-columns.last-section {
        padding-bottom: 60px;

        @media (max-width: 980px) {
            padding-bottom: 0;
        }
    }

    .column--text {
        @media (max-width: 980px) {
            .button {
                margin-bottom: 0;
            }
        }
    }
}

.about-small-image {
    overflow: hidden;
    max-width: 100%;

    &.hide-on-mobile {
        @media (max-width: 767px) {
            display: none;
        }
    }

    .container {
        max-width: 972px;
        margin: 0 auto;
        padding: 0 60px;
    }

    .about-small-image__inner {
        position: relative;
        display: inline-flex;

        img {
            position: relative;
            display: block;
            z-index: 2;
        }
    }

    .about-small__line {
        position: absolute;
        bottom: 0;
        right: 100%;
        height: 1px;
        width: 50vw;
        background-color: var(--klothed-textColor);
        opacity: 0.5;
    }

    img {
        border-radius: 0 0 10px;
    }
}

.about-self-image {
    background-color: var(--klothed-bgColor);
    margin-top: -1px;

    &.hide-on-mobile {
        @media (max-width: 767px) {
            display: none;
        }
    }

    .about-self-image__inner {
        max-width: 100%;
        overflow: hidden;
    }

    .about-self-image__element {
        position: relative;
        width: 555px;
        padding-right: 60px;
        padding-bottom: 137px;
        border-right: 1px solid rgba(255, 255, 255, 0.5);
        text-align: right;
        font-size: 0;

        @media (max-width: 1400px) {
            width: 350px;
        }

        @media (max-width: 1200px) {
            padding-bottom: 60px;
        }

        @media (max-width: 980px) {
            padding: 60px 0;
            margin: 0 auto;
            border-right: 0;
        }

        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 100%;
            width: 100vw;
            height: 1px;
            background-color: var(--klothed-textColor);
            opacity: 0.5;
            transform: translateX(-60px);

            @media (max-width: 980px) {
                display: none;
            }
        }

        img {
            display: inline-block;
            border-radius: 6px 0px 6px 6px;
        }
    }
}
