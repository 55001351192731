.filter {
    display: flex;
    border-top: 1px solid var(--klothed-textColor);
    border-bottom: 1px solid var(--klothed-textColor);
    padding: 16px 30px;
    flex-direction: column;
    width: 100%;
    font-family: "Commuters Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 16px;
    align-items: flex-start;
    /* identical to box height, or 145% */
    letter-spacing: 0.1em;
    gap: 14px;
    .header {
        display: flex;
        width: 100%;
        span {
            text-transform: uppercase;
            cursor: pointer;
            user-select: none;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 14px;
            width: 100%;
        }
        .close {
            height: 12px;
            margin-left: auto;
        }
    }
    form {
        width: 100%;
        max-width: 350px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-self: center;
        gap: 6px;
        > div {
            width: 100%;
            display: flex;
        }
        label {
            font-family: "Roboto", sans-serif;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            height: 16px;
        }

        input[type="submit"],
        input[type="reset"] {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            width: 100%;
            height: 24px;
            background: var(--klothed-bgColor);
            border-radius: 6px;

            font-family: "Commuters Sans";
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 20px;
            /* identical to box height, or 143% */

            display: flex;
            align-items: center;
            text-align: center;
            letter-spacing: 0.1em;
            text-transform: uppercase;

            color: var(--klothed-textColor);

            cursor: pointer;
        }

        .filter_price {
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-top: 6px;
        }
        .range-slider {
            margin: 9px 0;
        }
        .brands_title {
            margin-top: 16px;
            margin-bottom: 9px;
        }
        .sort_title {
            margin-top: 16px;
            margin-bottom: 9px;
        }
        .basic-multi-select {
            margin-bottom: 9px;
            .select__control {
                background-color: transparent;
                border-width: 0;
            }
            .select__indicator {
                color: var(--klothed-textColor);
            }
        }
    }
}
