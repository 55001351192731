.logo-slider {
    max-width: 328px;
    padding: 0 24px;
    margin: 0 auto;
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 6px;
}

.logo-wrapper {
    position: relative;
    max-width: 278px;
    padding: 0 24px;
    margin: 0 auto;
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 6px;

    @media (max-width: 980px) {
        max-width: 100%;
        border-radius: 6px 6px 6px 0;
        margin-bottom: 64px;
    }

    .logo__slide {
        display: flex;
        height: 88px;
        align-items: center;
        justify-content: center;
    }

    svg,
    img {
        @media (max-width: 980px) {
            max-width: 100%;
            height: auto;
        }
    }
}

.logo__viewport {
    overflow: hidden;
    width: 100%;
}

.logo__viewport.is-draggable {
    cursor: move;
    cursor: grab;
}

.logo__viewport.is-dragging {
    cursor: grabbing;
}

.logo__container {
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -webkit-tap-highlight-color: transparent;
    height: 88px;
}
