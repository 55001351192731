.share-section {
    display: flex;
    list-style: none;
    padding: 17px 0 0;
    margin: 16px 0 0;
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    gap: 32px;

    @media (max-width: 767px) {
        border-top: 0;
        padding-top: 0;
        margin-top: 10px;
    }

    a {
        transition: opacity 0.3s;

        &:hover {
            opacity: 0.7;
        }
    }
}
