.active-tabs {
    margin-bottom: 175px;

    @media (max-width: 980px) {
        margin-bottom: 68px;
    }

    .tab-line {
        display: flex;
        justify-content: center;
        list-style: none;
        padding: 0;
        margin: 0 0 120px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);

        @media (max-width: 980px) {
            flex-direction: row-reverse;
            margin-bottom: 60px;
        }

        li {
            position: relative;
            display: flex;
            justify-content: center;
            width: 48px;
            padding-bottom: 13px;

            @media (max-width: 980px) {
                padding-bottom: 5px;
            }

            &:after {
                content: "";
                width: 100%;
                height: 2px;
                position: absolute;
                bottom: -1px;
                left: 0;
                border-radius: 100%;
                opacity: 0;
                transition: opacity 0.3s;
                background: linear-gradient(90deg, #eaea15 25%, #ff855f 50%, #ff454e 75%, #0031ff 100%);
            }

            &.is-active {
                &:after {
                    opacity: 1;
                }
            }
        }

        button {
            background: none;
            border: 0;
            cursor: pointer;
        }

        svg {
            @media (max-width: 980px) {
                height: 25px;
            }
        }
    }
}
