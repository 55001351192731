.page-404 {
    box-sizing: border-box;
    color: var(--klothed-textColor);
    letter-spacing: 0.1em;
    margin: clamp(96px, 40%, 240px) auto 24px auto;
    font-family: Commuters Sans;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    max-width: min(calc(100vw - 55px), 500px);
    height: 100%;

    > p {
        font-family: "Commuters Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.1em;
        margin-bottom: 46px;
    }
    > p:first-child {
        font-family: "Commuters Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: 0.1em;
        margin-bottom: 26px;
    }

    button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        width: 100%;
        height: 48px;
        background: var(--klothed-textColor);
        border-radius: 6px;

        font-family: "Commuters Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        /* identical to box height, or 143% */

        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;

        color: var(--klothed-bgColor);

        cursor: pointer;
    }

    > h2:first-of-type {
        font-size: 14px;
        line-height: 20px;
        color: var(--klothed-textColor-trans50);
        letter-spacing: 0.1em;
    }
}
