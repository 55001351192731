.selectStyle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    top: 25vh;
    margin-left: 5vw;

    .selectStyle_leftPane {
        .selectStyle_leftPane_heading {
            font-family: "Sometimes Times";
            font-weight: 400;
            font-size: 53px;
            line-height: 64px;
            letter-spacing: 0.1em;
            color: var(--klothed-textColor);
            white-space: break-spaces;
        }
    }

    .selectStyle_rightPane {
        margin-right: calc(10vh + 200px);

        .selectStyleToggleGrp {
            display: flex;
            flex-direction: column;

            .selectStyleToggleItem {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-bottom: 32px;
                cursor: pointer;

                .styleSelectImg {
                    margin-right: 16px;
                }

                .styleSelectText {
                    font-family: "Commuters Sans";
                    font-weight: 400;
                    font-size: 16px;
                    letter-spacing: 0.1em;
                    color: var(--klothed-textColor);
                }
            }
        }

        .selectStyleNextBtn {
            margin-top: 56px;
            width: 328px;
            height: 48px;
            background: #ff855f;
            border-radius: 6px;
            font-family: "Commuters Sans";
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            color: var(--klothed-bgColor);
            pointer-events: none;
            opacity: 0.7;
        }

        .selectStyleNextBtnActive {
            pointer-events: initial;
            cursor: pointer;
            opacity: 1;
        }
    }
}
