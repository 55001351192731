.image-progress {
    position: relative;
    margin-bottom: 135px;

    @media (max-width: 980px) {
        padding-bottom: 90px;
        margin-bottom: 60px;
    }

    &:before {
        @media (max-width: 980px) {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: var(--klothed-textColor);
            opacity: 0.5;
        }
    }

    &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        left: 0;
        top: 127px;
        background-color: var(--klothed-textColor);
        opacity: 0.5;

        @media (max-width: 980px) {
            top: auto;
            bottom: 125px;
        }
    }

    .image-progress__inner {
        position: relative;
        display: flex;
        justify-content: space-between;
        z-index: 1;

        @media (max-width: 980px) {
            height: 460px;
        }

        &:before {
            content: "";
            position: absolute;
            width: 1px;
            height: 1000vh;
            left: -60px;
            top: 127px;
            background-color: var(--klothed-textColor);
            opacity: 0.5;
        }
    }

    .image-progress__item {
        @media (max-width: 980px) {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
        }

        .image {
            position: relative;
            width: 300px;
            height: 460px;
            opacity: 0;
            transition: opacity 0.3s;
            margin-bottom: 25px;

            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);

                @media (max-width: 767px) {
                    transform: translate(-50%, -50%);
                }
            }
        }

        .progress {
            .progress__inner {
                height: 2px;
                border-radius: 5px;
                background: linear-gradient(90deg, #eaea15 2.23%, #ff855f 37.24%, #ff454e 64.39%, #be0edb 92.69%, #ad00ff 98.91%);
                transform: scaleX(0);
                transform-origin: 0 0;
            }
        }

        &.is-active {
            .image {
                opacity: 1;
            }

            .progress {
                .progress__inner {
                    transition: transform 0.7s 0.3s;
                    transform: scaleX(1);
                }
            }
        }
    }
}

@keyframes image1 {
    0% {
        opacity: 1;
    }

    33%,
    100% {
        opacity: 0;
    }
}

@keyframes image2 {
    0%,
    33% {
        opacity: 0;
    }

    66% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes image3 {
    0%,
    66% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes progress1 {
    0% {
        width: 0;
    }

    33% {
        width: 100%;
    }

    34%,
    100% {
        width: 100%;
        opacity: 0;
    }
}

@keyframes progress2 {
    33% {
        width: 0;
    }

    66%,
    100% {
        width: 100%;
    }
}

@keyframes progress3 {
    0%,
    66% {
        width: 0;
    }

    100% {
        width: 100%;
    }
}
