.user_profile {
    box-sizing: border-box;
    color: var(--klothed-textColor);
    letter-spacing: 0.1em;

    .artifact_group {
        max-width: calc(100% - 48px);
    }
    .profile_section_v2 {
        display: flex;
        flex-direction: column;
        letter-spacing: 0.1em;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 24px;
        width: 100%;
        max-width: 400px;
        margin: 0 auto;

        .profile_title {
            font-family: "Commuters Sans";
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 28px;
            /* identical to box height, or 140% */
        }

        .profile_hints {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            /* identical to box height, or 133% */

            letter-spacing: 0.1em;

            color: var(--klothed-textColor-error);
        }
        .profile_entry {
            display: flex;
            flex-grow: 1;
            justify-content: space-between;
            align-items: center;
            margin-right: 24px;
            width: 100%;
            .icon_holder {
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid var(--klothed-textColor-trans50);
                border-radius: 6px;
                padding: 12px;
                width: 48px;
                height: 48px;

                font-weight: 600;
                font-size: 24px;
                line-height: 20px;
            }
            .text {
                display: flex;

                margin: 0 16px;

                flex-grow: 1;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;

                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                color: var(--klothed-textColor);

                > span {
                    color: var(--klothed-textColor-trans50);
                    margin-left: 0.5em;
                    margin-right: 0.5em;
                }
            }
            .edit {
                font-family: "Roboto";
                font-weight: 600;
                font-size: 12px;
                line-height: 14px;
                span,
                hr {
                    color: var(--klothed-textColor);
                }
            }
            .edit.in-review {
                font-style: italic;
                hr {
                    display: none;
                }
            }
        }
        .capitalize {
            text-transform: capitalize;
        }
        .profile_entry.disabled {
            cursor: not-allowed;
            pointer-events: none;
            color: var(--klothed-textColor-disabled);
            .icon_holder > svg > path {
                fill: var(--klothed-textColor-disabled);
            }
            .icon_holder > img {
                filter: brightness(50%);
            }
            .text {
                color: var(--klothed-textColor-disabled);
            }

            .edit > span,
            .edit:visited > span,
            .edit > hr,
            .edit:visited > hr {
                display: none;
                color: var(--klothed-textColor-trans20);
            }
        }
        .profile_entry.missing {
            color: var(--klothed-textColor-error);
            .icon_holder > svg > path {
                fill: var(--klothed-textColor-error);
            }
            .icon_holder > img {
                filter: brightness(50%);
            }
            .text {
                color: var(--klothed-textColor-error);
            }

            .edit > span,
            .edit:visited > span,
            .edit > hr,
            .edit:visited > hr {
                color: var(--klothed-textColor-error);
                border-color: var(--klothed-textColor-error);
            }
        }
    }
}
