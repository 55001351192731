.selectHeight {
    display: flex;
    flex-direction: row;
    position: relative;
    top: 25vh;
    justify-content: space-between;
    margin-left: 5vw;

    .selectHeight_leftPane {
        .selectHeight_leftHeading {
            font-family: "Sometimes Times";
            font-weight: 400;
            font-size: 53px;
            line-height: 64px;
            letter-spacing: 0.1em;
            color: var(--klothed-textColor);
            white-space: break-spaces;
        }
    }

    .selectHeight_rightPane {
        margin-right: calc(10vw + 124px);

        .heightIpGrp {
            .heightIp_ {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .heightIp_Input {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    border: 2px solid #ff855f;
                    border-radius: 6px;
                    height: 48px;
                    //width: 158px;

                    .heightIp_Box {
                        height: 20px;
                        width: 20px;
                        border: none;
                        outline: none;
                        margin-left: 9px;
                        font-family: "Commuters Sans";
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 20px;
                        letter-spacing: 0.1em;
                        color: var(--klothed-textColor);
                        background-color: var(--klothed-bgColor);
                    }

                    .heightIp_BoxInches {
                        width: 32px;
                    }

                    .heightIp_BoxCentimeters {
                        width: 48px;
                    }

                    .heightIp_BoxLabel {
                        font-family: "Commuters Sans";
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 20px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        letter-spacing: 0.1em;
                        color: rgba(255, 255, 255, 0.7);
                    }

                    .heightIp_BoxLabelFeet {
                        margin-right: 36px;
                    }

                    .heightIp_BoxLabelInches {
                        margin-right: 10px;
                    }
                }

                .heightIp_Unit {
                    display: flex;
                    flex-direction: row;

                    .heightIp_Unit_ {
                        font-family: "Commuters Sans";
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 20px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        letter-spacing: 0.1em;
                        text-transform: uppercase;
                        color: rgba(255, 255, 255, 0.7);
                        height: 48px;
                        width: 48px;
                        cursor: pointer;
                    }

                    .heightIp_Unit_Inches.selected_unit {
                        color: var(--klothed-bgColor);
                        background: rgba(255, 133, 95, 0.5);
                        border: 2px solid #ff855f;
                        border-radius: 6px 0px 0px 6px;
                    }

                    .heightIp_Unit_Centimeters.unselected_unit {
                        border: 2px solid rgba(255, 255, 255, 0.5);
                        border-radius: 0px 6px 6px 0px;
                        border-left: none;
                    }

                    .heightIp_Unit_Centimeters.selected_unit {
                        color: var(--klothed-bgColor);
                        background: rgba(255, 133, 95, 0.5);
                        border: 2px solid #ff855f;
                        border-radius: 0px 6px 6px 0px;
                    }

                    .heightIp_Unit_Inches.unselected_unit {
                        border: 2px solid rgba(255, 255, 255, 0.5);
                        border-radius: 6px 0px 0px 6px;
                        border-right: none;
                    }
                }
            }

            .heightIpNext {
                margin-top: 56px;
                width: 328px;
                height: 48px;
                background: #ff855f;
                border-radius: 6px;
                font-family: "Commuters Sans";
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                letter-spacing: 0.1em;
                text-transform: uppercase;
                color: var(--klothed-bgColor);
                pointer-events: none;
                opacity: 0.7;
            }

            .heightIpNextActive {
                pointer-events: initial;
                cursor: pointer;
                opacity: 1;
            }
        }
    }
}
