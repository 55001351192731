.user_verify {
    .artifact_group {
        position: relative;
        width: 100%;
        margin-top: 95px;

        @media (max-width: 980px) {
            display: block;
            position: static;
            height: auto;
            width: auto;
            padding: 30px 24px 0;
            text-align: center;
        }

        .button {
            display: none;

            @media (max-width: 980px) {
                display: flex;
                margin: 0 auto;
            }
        }

        h4 {
            display: none;

            @media (max-width: 980px) {
                display: block;
                margin: 0 0 13px;
                text-align: center;
                font-size: 28px;
                font-weight: normal;
                font-family: "Sometimes Times";
                color: var(--klothed-textColor);
            }
        }

        .image {
            position: relative;
            max-width: 100%;
            @media (max-width: 980px) {
                transform: none;
                height: auto;
            }
        }

        .brandsSec {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
            background-color: var(--klothed-logo-black);

            @media (max-width: 980px) {
                position: static;
                display: block;
                top: auto;
                max-width: 328px;
                margin: 0 auto;
            }

            .logo-wrapper {
                @media (max-width: 980px) {
                    margin: 30px 0 0;
                }
            }
        }
    }

    .verify_desktop {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        margin-top: 95px;
        margin-bottom: 20px;
        .artifact_group {
            display: flex;
            flex-direction: column;
            margin-left: auto;
            margin-right: auto;
            align-items: center;
            flex-grow: 1;
            .brandsSec {
                display: none;
            }
            @media (min-width: 980px) {
                max-width: 40vw;
                margin-right: 40px;
                .brandsSec {
                    margin-top: 20px;
                    display: flex;
                }
            }
        }
        .verify_group {
            display: flex;
            flex-direction: column;
            margin-right: auto;
            margin-left: auto;
            margin-top: 95px;
            align-items: center;
            align-items: center;
            justify-content: center;
            flex-grow: 1;
            @media (min-width: 980px) {
                max-width: 40vw;
                margin-left: 40px;
            }
        }
    }
}
