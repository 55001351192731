:root {
    --klothed-bgColor: oklch(97.02% 0 0);
    --klothed-textColor: oklch(24.16% 0.015 256.81);
    --klothed-tile-textColor: oklch(0% 0 0);
    --klothed-tile-bgColor: oklch(100% 0 0);
    --klothed-textColor-dark70: color-mix(in oklch, var(--klothed-textColor) 70%, var(--klothed-bgColor));
    --klothed-textColor-disabled: color-mix(in oklch, var(--klothed-textColor) 50%, var(--klothed-bgColor));
    --klothed-textColor-dark20: color-mix(in oklch, var(--klothed-textColor) 70%, var(--klothed-bgColor));
    --klothed-textColor-trans70: rgba(27, 32, 39, 0.7);
    --klothed-textColor-trans50: rgba(27, 32, 39, 0.5);
    --klothed-textColor-trans20: rgba(27, 32, 39, 0.2);
    --klothed-bgColor-trans50: rgba(245, 245, 245, 0.5);
    --klothed-bgColor-trans80: rgba(245, 245, 245, 0.8);
    --klothed-textBorder-width: 2px;
    --klothed-whiteImg-filter: invert(1);
    --klothed-blackImg-filter: none;
    --klothed-logo-white: var(--klothed-textColor);
    --klothed-logo-black: var(--klothed-bgColor);
    --klothed-textColor-error: #ef1320;
}

.theme-dark {
    --klothed-bgColor: oklch(0% 0 0);
    --klothed-textColor: oklch(100% 0 0);
    --klothed-textColor-dark70: rgba(255, 255, 255, 0.7);
    --klothed-textColor-dark20: rgba(255, 255, 255, 0.2);
    --klothed-textBorder-width: 1px;
    --klothed-bgColor-trans50: rgba(0, 0, 0, 0.5);
    --klothed-bgColor-trans80: rgba(0, 0, 0, 0.8);
    --klothed-whiteImg-filter: none;
    --klothed-blackImg-filter: invert(1);
    --klothed-textColor-trans70: rgba(255, 255, 255, 0.7);
    --klothed-textColor-trans50: rgba(255, 255, 255, 0.5);
    --klothed-textColor-trans20: rgba(255, 255, 255, 0.2);
    --klothed-bgColor-trans50: rgba(0, 0, 0, 0.5);
    --klothed-bgColor-trans80: rgba(0, 0, 0, 0.8);
}
