.homeLandingPage {
    width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .homeLandingPage_leftPane {
        width: 50%;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .picLarge {
            margin-right: -50px;
            max-width: 100%;
            height: auto;
        }

        .picSmall {
            position: absolute;
            right: calc(50% + 20px);
            align-self: center;
            bottom: 10vh;
            max-width: 100vw;
            height: auto;
        }
    }

    .homeLandingPage_rightPane {
        width: 50%;
        box-sizing: border-box;
        padding-right: 5vw;

        .homeLandingPage_rightPane_topText {
            font-family: "Sometimes Times";
            font-weight: 400;
            font-size: 28px;
            line-height: 40px;
            letter-spacing: 0.1em;
            color: var(--klothed-textColor);

            .text_italic {
                font-style: italics;
            }

            .text_capitalize {
                text-transform: capitalize;
            }

            sup {
                display: inline-block;
                transform: translateY(-10px);
                font-size: 6px;
                font-family: "Inter";
                letter-spacing: 0;
            }
        }

        .homeLandingPage_rightPane_line {
            width: 100%;
            height: 1px;
            background: linear-gradient(90deg, #eaea15, #ff855f, #ff454e, #0031ff);
            margin: 24px 0px;
        }

        .homeLandingPage_rightPane_text {
            font-family: "Sometimes Times";
            font-weight: 400;
            font-size: 53px;
            line-height: 64px;
            letter-spacing: 0.1em;
            color: var(--klothed-textColor);
            white-space: break-spaces;
        }
    }
}
