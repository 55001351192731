.racks_home {
    display: flex;
    width: 100%;
    flex-flow: column;
    .infinite-scroll-component__outerdiv {
        margin: 95px 0 auto 0;
        width: calc(100vw - (100vw - 100%));
    }

    .artifact_group {
        display: flex;
        margin: 95px auto 0 auto;
        min-height: calc(100vh - 380px);
        width: min(calc(100vw - 75px), 400px);
        flex-direction: column;
        font-family: Commuters Sans;
        align-self: center;
    }

    .catalog_products_page {
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin-top: 56px;

        .react-horizontal-scrolling-menu--footer {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .style-count {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            /* identical to box height, or 117% */

            text-align: center;

            margin: 21px auto 0 auto;
            letter-spacing: 0.1em;

            color: var(--klothed-textColor);
        }

        .react-horizontal-scrolling-menu--item:empty {
            margin-left: -8px;
            margin-right: -8px;
        }

        .uploadPhoto {
            margin: 0 auto 12px auto;
            display: flex;
            gap: 4px;
            flex-direction: column;
            max-width: 100%;
            span {
                font-family: "Roboto", sans-serif;
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                letter-spacing: 0.1em;
            }
            div {
                display: flex;
                flex-direction: column;
                width: fit-content;
                margin-top: 24px;
                line-height: 14px;
                span {
                    font-weight: 600;
                    margin-bottom: 4px;
                }
            }
        }

        .react-horizontal-scrolling-menu--scroll-container {
            &::-webkit-scrollbar {
                display: none;
            }
            gap: 8px;
            margin-left: 24px;
        }
        .catalogProductsTitle {
            display: flex;
            flex-direction: row;
            margin: 0 24px 24px 24px;
            align-items: center;
            justify-content: center;

            font-family: "Commuters Sans";
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 28px;
            user-select: none;

            position: relative;
            &.minimized {
                margin-bottom: 4px;
                > button {
                    display: none;
                }
            }

            span {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }

            .expand {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: auto;
                margin-left: 14px;
            }

            .shop-all {
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                margin-left: auto;
                gap: 4px;
                > span {
                    text-align: center;
                    font-family: "Roboto";
                    font-style: normal;
                    font-weight: 600;
                    letter-spacing: 0.1em;
                    font-size: 12px;
                    line-height: 16px;
                }
            }

            .arrow {
                position: absolute;
                display: flex;
                top: 180px;
                z-index: 1;
                align-items: center;
                justify-content: center;
                font-size: 24px;
                background: var(--klothed-bgColor-trans50);
                color: var(--klothed-textColor);
                border: 0;
                border-radius: 35px;
                cursor: pointer;
                min-height: 43px;
                min-width: 43px;
                opacity: 1;
                outline: 0;
                transition: all 0.5s;
                &:hover {
                    background-color: var(--klothed-bgColor-trans80);
                }
                &:disabled {
                    display: none;
                }
            }
            .left-arrow {
                left: calc(4% + 1px);
            }
            .right-arrow {
                right: calc(4% + 1px);
            }
            .contextDots {
                &:after {
                    content: "\2807";
                    font-size: 25px;
                    color: var(--klothed-textColor);
                }
            }
        }

        .catalog_products_pageContentMobile {
            display: flex;
            justify-content: flex-start;
            overflow-x: auto;
            overflow-y: hidden;
            max-width: 100vw;
            gap: 16px;
            padding-bottom: 8px;
            margin-left: 24px;
        }

        .catalog_products_pageContentMobile.grid {
            flex-flow: wrap;
        }
    }
}
