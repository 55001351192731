.homeProfile {
    display: flex;
    flex-direction: row;
    min-height: 100vh;
    justify-content: center;
    align-items: center;

    .homeProfile_headerText {
        position: fixed;
        right: 5vw;
        text-transform: capitalize;
        top: calc(6vh + 75px);
        font-family: "Commuters Sans";
        font-weight: 400;
        font-size: 16px;
        letter-spacing: 0.1em;
        color: var(--klothed-textColor);
    }

    .homeProfile_leftPane {
        margin-left: 5vw;
        width: 45%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .homeProfile_leftPane_IconGrp {
            display: flex;
            flex-direction: row;
            margin: 8px 0px;
            width: max-content;
            opacity: 0.5;
            pointer-events: none;

            .homeProfile_leftPane_IconDiv {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 48px;
                height: 48px;
                border: 1px solid rgba(255, 255, 255, 0.5);
                border-radius: 6px;
                margin-right: 16px;
            }

            .homeProfile_leftPane_IconDivSelected {
                border: 1px solid #ff855f;
                background: rgba(255, 133, 95, 0.5);
            }

            .homeProfile_leftPane_IconImg {
                width: 36px;
                height: 48px;
                border-radius: 6px;
            }

            .homeProfile_leftPane_IconValue {
                font-family: "Commuters Sans";
                font-weight: 400;
                font-size: 16px;
                letter-spacing: 0.1em;
                color: var(--klothed-textColor);
                align-self: center;
                display: flex;
                flex-direction: row;

                .passwordRounds {
                    height: 8px;
                    width: 8px;
                    background: var(--klothed-textColor);
                    margin: 0px 3px;
                    border-radius: 100%;
                }
            }
        }

        .activeIconGrp {
            pointer-events: inherit;
            opacity: 1;
            cursor: pointer;
        }
    }

    .homeProfile_rightPane {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        .homeProfile_rightPane_name {
            .email_input_div,
            .password_input_div {
                position: relative;
                margin: 16px 0px;

                .email_input_label,
                .password_input_label {
                    position: absolute;
                    font-size: 1rem;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    background-color: var(--klothed-bgColor);
                    color: gray;
                    margin-left: 15px;
                    padding: 0px 5px;
                    transition: 0.2s ease-out;
                    transform-origin: left top;
                    pointer-events: none;
                    font-family: "Commuters Sans";
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    letter-spacing: 0.1em;
                    color: rgba(255, 255, 255, 0.7);
                }

                .email_input_box,
                .password_input_box {
                    height: 48px;
                    width: 306px;
                    border: 1px solid rgba(255, 255, 255, 0.5);
                    border-radius: 6px;
                    outline: none;
                    font-family: "Commuters Sans";
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    letter-spacing: 0.1em;
                    color: var(--klothed-textColor);
                    background-color: var(--klothed-bgColor);
                    padding: unset;
                    padding-left: 20px;
                }

                .email_input_box:focus,
                .password_input_box:focus {
                    border-color: #ff855f;
                }

                .email_input_box:focus + .email_input_label,
                .password_input_box:focus + .password_input_label {
                    color: #ff855f;
                    top: 0;
                    transform: translateY(-40%) translateX(-2%) scale(0.9);
                }

                .email_input_box:not(:placeholder-shown) + .email_input_label,
                .password_input_box:not(:placeholder-shown) + .password_input_label {
                    top: 0;
                    transform: translateY(-40%) translateX(-2%) scale(0.9);
                }

                .showPasswordIMG {
                    position: absolute;
                    right: 10px;
                    bottom: 17px;
                    cursor: pointer;
                }
            }

            .nameSubmitBtn {
                background: #ff855f;
                border-radius: 6px;
                width: 328px;
                height: 48px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-family: "Commuters Sans";
                font-weight: 600;
                font-size: 14px;
                letter-spacing: 0.1em;
                text-transform: uppercase;
                color: var(--klothed-bgColor);
                margin-top: 56px;
                pointer-events: none;
                opacity: 0.5;
            }

            .nameSubmitBtnActive {
                cursor: pointer;
                pointer-events: inherit;
                opacity: 1;
            }

            .passwordSuggestionText {
                font-family: "Commuters Sans";
                font-weight: 400;
                font-size: 12px;
                letter-spacing: 0.1em;
                color: var(--klothed-textColor);
                max-width: 328px;
                margin-top: 48px;
                line-height: 16px;
            }
        }

        .changeProfileImageDiv {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .deleteImageDiv {
                align-self: flex-end;
                height: 32px;
                width: 32px;
                border: 1px solid rgba(255, 255, 255, 0.5);
                border-radius: 6px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                position: relative;
                margin-bottom: -17px;
                top: 30px;
                right: 15px;
                background: var(--klothed-bgColor);
                cursor: pointer;
            }

            .changeProfileImage {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .profileImage {
                    width: 375px;
                    height: 500px;
                    border-radius: 6px;
                }

                .changeImageBtn {
                    width: 328px;
                    height: 48px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: 2px solid #ff855f;
                    border-radius: 6px;
                    font-family: "Commuters Sans";
                    font-weight: 600;
                    font-size: 14px;
                    letter-spacing: 0.1em;
                    text-transform: uppercase;
                    color: #ff855f;
                    cursor: pointer;
                    margin-top: 56px;
                }
            }
        }

        .selectHeightProfile {
            top: 0px;
            margin: 0px;
            position: initial;

            .selectHeight_leftPane {
                display: none;
            }

            .selectHeight_rightPane {
                margin: 0px;
            }
        }

        .selectStyleProfile {
            top: 0px;
            margin: 0px;
            position: initial;

            .selectStyle_leftPane {
                display: none;
            }

            .selectStyle_rightPane {
                margin: 0px;
            }
        }
    }
}
