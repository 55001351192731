.container {
    max-width: 1310px;
    margin: 0 auto;
    padding: 0 64px;

    &.smaller {
        max-width: 1200px;
    }

    @media (max-width: 1200px) {
        padding: 0 24px;
    }
}
