.two-columns {
    position: relative;
    color: var(--klothed-textColor);
    z-index: 1;
    max-width: 100%;
    overflow: hidden;

    &.overflow-visible {
        overflow: visible;
    }

    &--white {
        background-color: var(--klothed-textColor);
        color: var(--klothed-bgColor);
    }

    &.two-columns--spacing {
        padding: 136px 0;

        @media (max-width: 980px) {
            padding: 64px 0 0;
        }
    }

    .line {
        position: absolute;
        bottom: 0;
        background-color: var(--klothed-textColor);
        opacity: 0.5;

        &--right {
            height: 1px;
            width: 100vw;
            left: 3px;

            @media (max-width: 980px) {
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }

    .two-columns__inner {
        position: relative;
        display: flex;

        @media (max-width: 980px) {
            flex-wrap: wrap;
        }
    }

    &.align-center {
        .two-columns__inner {
            align-items: center;
        }
    }

    &.align-start {
        .two-columns__inner {
            align-items: flex-start;
        }
    }

    &.justify-space-between {
        .two-columns__inner {
            justify-content: space-between;
        }
    }

    &.justify-flex-end {
        .two-columns__inner {
            justify-content: flex-end;
        }
    }

    .column--text {
        padding: 0 55px;
        max-width: 575px;

        @media (max-width: 1200px) {
            padding: 0 20px;
        }

        @media (max-width: 980px) {
            padding: 64px 0;
            max-width: 100%;
        }
    }

    .column--larger {
        max-width: 640px;
    }

    .column {
        @media (max-width: 980px) {
            flex: 0 0 100%;
        }
        .image {
            display: inline-flex;
            max-width: 456px;
        }
    }

    .column--order {
        @media (max-width: 980px) {
            order: -1;
        }
    }

    .column--right {
        display: flex;
        justify-content: flex-end;
        flex: 0 0 456px;
        max-width: 456px;

        @media (max-width: 980px) {
            width: calc(100% + 48px);
            max-width: calc(100% + 48px);
            flex: 0 0 calc(100% + 48px);
            margin-left: -24px;
        }
    }

    .column--fixed-width {
        display: flex;
        justify-content: flex-end;
        width: 615px;
        flex: 0 0 615px;
        border-right: 1px solid rgba(0, 0, 0, 0.2);
        padding: 136px 0;

        @media (max-width: 1400px) {
            width: 410px;
            flex: 0 0 410px;
        }

        @media (max-width: 980px) {
            border-right: 0;
            width: 100%;
            flex: 0 0 100%;
            padding: 60px 0 0;
        }

        @media (max-width: 767px) {
            margin: 0 0 0 -24px;
            width: calc(100% + 48px);
            flex: 0 0 calc(100% + 48px);
        }

        img {
            @media (max-width: 980px) {
                width: 100%;
                height: auto;
            }
        }
    }

    .column--no-left {
        padding-left: 0;
    }

    .column--no-right {
        padding-right: 0;
    }

    .top-space {
        padding-top: 70px;
    }

    .fixed-width {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 382px;
        padding-right: 100px;

        @media (max-width: 1200px) {
            padding-right: 0;
        }

        @media (max-width: 980px) {
            display: block;
            width: auto;
        }
    }

    .border-left {
        position: relative;

        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 1px;
            height: 200%;
            background-color: var(--klothed-textColor);
            opacity: 0.5;

            @media (max-width: 980px) {
                top: 0;
                left: 50%;
                height: 1px;
                width: 100vw;
            }
        }
    }

    h5 {
        padding: 0;
        font-weight: normal;
        margin: 0 0 13px;
        font-size: 20px;
        line-height: 1.6;
        font-family: "Commuters Sans";
        letter-spacing: 0.1em;
        text-transform: uppercase;

        @media (max-width: 1200px) {
            font-size: 16px;
            line-height: 1.25;
            margin-bottom: 10px;
        }

        @media (max-width: 980px) {
            margin-bottom: 6px;
        }
    }

    h3 {
        padding: 0;
        margin: 0 0 56px;
        font-weight: normal;
        font-size: 53px;
        line-height: 1.2;
        letter-spacing: 0.1em;
        font-family: "Sometimes Times";

        &.spacing {
            margin: 15px 0 20px;
        }

        &.spacing2 {
            margin: 15px 0 34px;
        }

        @media (max-width: 1200px) {
            font-size: 40px;
            line-height: 1.4;
            margin-bottom: 30px;
        }

        &.h4 {
            @media (max-width: 767px) {
                font-size: 28px;
                line-height: 1.428571;
            }
        }

        span {
            background: linear-gradient(90deg, #eaea15 2.23%, #ff855f 37.24%, #ff454e 64.39%, #be0edb 92.69%, #ad00ff 98.91%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            box-decoration-break: clone;
        }
    }

    img {
        display: block;
    }

    .right-image {
        position: relative;
        display: inline-flex;

        @media (max-width: 980px) {
            display: block;
        }

        &:before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 10px;
            width: 100vw;
            height: 1px;
            background-color: var(--klothed-textColor);
            opacity: 0.5;

            @media (max-width: 980px) {
                display: none;
            }
        }

        img {
            position: relative;
            z-index: 1;
            border-radius: 6px 6px 0 6px;
            max-width: 100%;
            height: auto;

            @media (max-width: 980px) {
                width: 100%;
                border-radius: 6px;
            }
        }
    }

    .image-element {
        @media (max-width: 980px) {
            position: relative;
            z-index: 2;
            margin: 0 0 16px;
        }

        &.mobile-move {
            @media (max-width: 980px) {
                margin-top: -64px;
                margin-bottom: -30px;
            }
        }

        img {
            height: auto;
            max-width: 100%;

            @media (max-width: 980px) {
                margin: 0 auto;
            }
        }
    }

    .hide-mobile {
        @media (max-width: 980px) {
            display: none;
        }
    }

    .show-mobile {
        display: none;

        @media (max-width: 980px) {
            display: block;
            margin: 0 0 64px;
        }
    }
}
