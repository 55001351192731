.footer_section {
    right: 5vw;
    z-index: 2;

    p {
        margin: 15px 0 0;
    }

    .footer_section-logo {
        display: inline-block;
        transform: translateX(-8px);
    }
}
