.user_signup {
    .artifact_group {
        position: relative;
        width: 100%;
        margin-top: 95px;

        @media (max-width: 980px) {
            display: block;
            position: static;
            height: auto;
            width: auto;
            padding: 30px 24px 0;
            text-align: center;
        }

        .button {
            display: none;

            @media (max-width: 980px) {
                display: flex;
                margin: 0 auto;
            }
        }

        h4 {
            display: none;

            @media (max-width: 980px) {
                display: block;
                margin: 0 0 13px;
                text-align: center;
                font-size: 28px;
                font-weight: normal;
                font-family: "Sometimes Times";
                color: var(--klothed-textColor);
            }
        }

        .image {
            position: relative;
            max-width: 100%;
            @media (max-width: 980px) {
                transform: none;
                height: auto;
            }
        }

        .brandsSec {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
            background-color: var(--klothed-logo-black);

            @media (max-width: 980px) {
                position: static;
                display: block;
                top: auto;
                max-width: 328px;
                margin: 0 auto;
            }

            .logo-wrapper {
                @media (max-width: 980px) {
                    margin: 30px 0 0;
                }
            }
        }
    }

    .signup_desktop {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        margin-top: 95px;
        margin-bottom: 20px;
        .artifact_group {
            display: flex;
            flex-direction: column;
            margin-left: auto;
            margin-right: auto;
            align-items: center;
            flex-grow: 1;
            .brandsSec {
                display: none;
            }
            @media (min-width: 980px) {
                max-width: 40vw;
                margin-right: 40px;
                .brandsSec {
                    margin-top: 20px;
                    display: flex;
                }
            }
        }
        .signup_group {
            display: flex;
            flex-direction: column;
            margin-right: auto;
            margin-left: auto;
            margin-top: 95px;
            align-items: center;
            align-items: center;
            justify-content: center;
            flex-grow: 1;
            @media (min-width: 980px) {
                max-width: 40vw;
                margin-left: 40px;
            }
            input,
            .signupBtn_wrapper {
                width: 100%;
                .signup_button {
                    width: calc(100% - 4px);
                }
            }
            .showPasswordIMG {
                filter: grayscale(100);
            }
        }
    }

    .signUp_section_v2 {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        letter-spacing: 0.1em;
        width: 100%;
        max-width: 380px;
        // padding: 5vh 1em;
        margin: 0 auto;
        display: flex;

        .errorMessage__ {
            height: 60px;

            .errorMessageHeading {
                font-family: "Commuters Sans";
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                letter-spacing: 0.1em;
                color: var(--klothed-textColor-error);
            }
        }

        .signUp_top_header {
            font-family: "Sometimes Times";
            font-weight: 400;
            font-size: 28px;
            line-height: 40px;
            color: var(--klothed-textColor);
            white-space: break-spaces;
            margin-bottom: 16px;
        }

        .email_input_div,
        .password_input_div {
            position: relative;
            margin: 8px 0px;
            width: 100%;

            .email_input_label,
            .password_input_label {
                position: absolute;
                font-size: 1rem;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                background-color: var(--klothed-bgColor);
                color: gray;
                margin-left: 15px;
                padding: 0px 5px;
                transition: 0.2s ease-out;
                transform-origin: left top;
                pointer-events: none;
                font-family: "Commuters Sans";
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.1em;
                color: var(--klothed-textColor-trans70);
            }

            .email_input_box,
            .password_input_box {
                height: 48px;
                border: 1px solid var(--klothed-textColor-trans50);
                border-radius: 6px;
                outline: none;
                font-family: "Commuters Sans";
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.1em;
                color: var(--klothed-textColor) !important;
                background-color: var(--klothed-bgColor) !important;
                padding: unset;
                padding-left: 20px;
                width: 100%;
            }

            .email_input_box_error,
            .password_input_box_error {
                border: 1px solid var(--klothed-textColor-error);
            }

            .email_input_label_error,
            .password_input_label_error {
                color: var(--klothed-textColor-error);
            }

            .password_input_box {
                font-family: unset;
                font-size: 22px;
            }

            .showPassword {
                font-family: "Commuters Sans";
                font-size: 16px;
            }

            .email_input_box:focus,
            .password_input_box:focus {
                border-color: #ff855f;
            }

            .email_input_box:focus + .email_input_label,
            .password_input_box:focus + .password_input_label {
                color: #ff855f;
                top: 0;
                transform: translateY(-40%) translateX(-2%) scale(0.9);
            }

            .email_input_box:not(:placeholder-shown) + .email_input_label,
            .password_input_box:not(:placeholder-shown) + .password_input_label {
                top: 0;
                transform: translateY(-40%) translateX(-2%) scale(0.9);
            }

            .showPasswordIMG {
                position: absolute;
                right: 10px;
                bottom: 17px;
                cursor: pointer;
            }
        }

        .signUp_button {
            background: linear-gradient(90deg, #eaea15 2.23%, #ff855f 29.99%, #ff454e 51.12%, #0031ff 96.38%);
            border-radius: 6px;
            width: 100%;
            height: 50px;
            font-family: "Commuters Sans";
            font-weight: 600;
            font-size: 14px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-transform: uppercase;
            color: var(--klothed-tile-bgColor);
            margin: 8px 0px 16px;
            opacity: 0.3;
            pointer-events: none;
            border: none;
        }

        .signUp_button_active {
            opacity: 1;
            pointer-events: initial;
            cursor: pointer;
        }

        .legal {
            .legal_acceptance {
                width: 100%;
                margin: 8px auto;
                display: flex;
                flex-direction: column;
                align-items: center;
                font-family: "Commuters Sans";
                div {
                    display: flex;
                    color: var(--klothed-textColor);

                    > span {
                        display: inline-flex;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 18px;
                        letter-spacing: 0.1em;
                        color: var(--klothed-textColor);
                        width: fit-content;
                    }
                    .legal_acceptance_link {
                        font-family: "Commuters Sans";
                        font-weight: 400;
                        font-size: 12px;
                        display: inline-flex;
                        flex-direction: column;
                        width: fit-content;
                        line-height: 18px;
                        cursor: pointer;
                        align-items: center;
                        justify-content: center;
                        &:last-of-type {
                            margin-left: 1ch;
                        }
                        &:first-of-type {
                            margin-right: 1ch;
                        }
                    }
                }
            }
            .legal_disclaimer {
                font-family: "Commuters Sans";
                font-weight: 400;
                font-size: 10px;
                line-height: 14px;
                letter-spacing: 0.1em;
                color: var(--klothed-textColor);
                margin: 8px auto;
                margin-bottom: 4px;
                width: fit-content;
                color: var(--klothed-textColor-trans50);
            }
        }

        .alternate_login_options {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            margin: 8px 0px;
            gap: 16px;

            .alternate_signUp {
                font-family: "Commuters Sans";
                font-weight: 400;
                font-size: 16px;
                color: var(--klothed-textColor);
                align-self: center;
            }

            .alternate_login_options_CTA {
                cursor: pointer;

                &.apple-login {
                    display: none;

                    @media (max-width: 980px) {
                        display: inline;
                    }
                }
            }
        }

        .horizontal_separator {
            height: 1px;
            background-color: var(--klothed-textColor-trans50);
            width: 100%;
            margin: 16px 0px 16px;
        }

        .horizontal_separator_error {
            height: 2px;
            background-color: rgba(239, 19, 32, 0.2);
            margin: 8px 0px 16px;
        }

        .already_account {
            font-family: "Commuters Sans";
            font-weight: 400;
            font-size: 12px;
            color: var(--klothed-textColor);
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            .login_button {
                width: 64px;
                height: 32px;
                font-family: "Commuters Sans";
                font-weight: 600;
                font-size: 11px;
                display: flex;
                align-items: center;
                justify-content: center;
                text-transform: uppercase;
                cursor: pointer;
                border: 1px solid var(--klothed-textColor-trans50);
                border-radius: 6px;
                margin-left: 16px;
                background: linear-gradient(90deg, #eaea15 2.23%, #ff855f 37.24%, #ff454e 64.39%, #be0edb 80.69%, #ad00ff 84.91%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }

        .store_CTA {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            font-family: "Commuters Sans";
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.1em;
            color: var(--klothed-textColor);

            a {
                text-decoration: none;
            }

            .selector {
                display: flex;
                flex-direction: column;

                .getApp {
                    margin-bottom: 18px;
                    font-size: 11px;
                    color: var(--klothed-textColor);
                    text-transform: uppercase;
                }

                .appleAppStore {
                    cursor: pointer;
                }

                .extensionLink {
                    cursor: pointer;
                    display: flex;
                    flex-direction: row;
                    width: 189px;
                    height: 38px;
                    background: var(--klothed-bgColor);
                    border: 1px solid #727272;
                    border-radius: 9px;

                    .chromeLogo {
                        height: 24px;
                        width: 24px;
                        margin-left: 12px;
                        margin-right: 8px;
                        align-self: center;
                    }

                    .extensionText {
                        display: flex;
                        flex-direction: column;
                        color: var(--klothed-textColor);
                        justify-content: space-evenly;

                        .extensionTextTop {
                            font-size: 10px;
                        }

                        .extensionTextBottom {
                            font-size: 14px;
                            letter-spacing: 0.01em;
                        }
                    }
                }
            }
        }
    }
}
