.policy-page {
    box-sizing: border-box;
    color: var(--klothed-textColor);
    letter-spacing: 0.1em;
    margin: 96px auto 24px auto;
    font-family: Commuters Sans;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    max-width: min(calc(100vw - 55px), 500px);

    > p:first-child {
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
    }

    > h2:first-of-type {
        font-size: 14px;
        line-height: 20px;
        color: var(--klothed-textColor-trans50);
        letter-spacing: 0.1em;
    }
    h4 {
        font-size: 14px;
        line-height: 18px;
        font-weight: 600;
        font-style: normal;
        text-align: left;
        letter-spacing: 0.1em;
        font-family: Commuters Sans;
    }

    li,
    li > h3,
    li > h4 {
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        font-style: normal;
        text-align: left;
        letter-spacing: 0.1em;
        font-family: Commuters Sans;
    }

    li > p:first-child {
        margin: 0;
    }
}
