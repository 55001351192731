@keyframes progressBar {
    0% {
        width: 100%;
    }

    100% {
        width: 0%;
    }
}

.upload_photo_section {
    display: flex;
    flex-direction: row;
    width: auto;
    position: relative;
    top: 25vh;
    justify-content: space-between;

    .instructionSetBar {
        width: 100%;
        height: 1px;
        background: linear-gradient(90deg, #eaea15, #ff855f, #ff454e, #0031ff);
        margin-top: 24px;
        display: flex;
        justify-content: flex-end;
        opacity: 0.7;

        .instructionSetBarInside {
            width: 0%;
            height: 1px;
            background-color: black;
            animation: progressBar 3s linear;
        }
    }

    .upload_photo_left_pane {
        margin-left: 5%;
        max-width: 36.5%;
        flex-basis: 36.5%;

        .leftPane_one {
            display: flex;
            flex-direction: column;

            .leftPane_one_heading {
                font-family: "Sometimes Times";
                font-weight: 400;
                font-size: 53px;
                line-height: 64px;
                letter-spacing: 0.1em;
                color: var(--klothed-textColor);
                white-space: break-spaces;
            }

            .leftPane_one_heading_italycs {
                font-style: italic;
            }
        }

        .leftPane_two {
            display: flex;
            flex-direction: column;
            width: 40vw;
            margin-top: 125px;

            .leftPane_twoIconSet {
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;

                .instructionSetDiv {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 88px;
                    width: 88px;
                    border: 1px solid rgba(255, 255, 255, 0.5);
                    border-radius: 6px;
                }
            }

            .instructionSetHeading {
                font-family: "Sometimes Times";
                font-style: normal;
                font-weight: 400;
                font-size: 53px;
                line-height: 64px;
                text-align: center;
                letter-spacing: 0.1em;
                color: var(--klothed-textColor);
                margin-top: 60px;
            }

            .instructionSetText {
                font-family: "Commuters Sans";
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                text-align: center;
                letter-spacing: 0.1em;
                color: var(--klothed-textColor);
                margin-top: 20px;
            }

            .instructionSetBar {
                margin-top: 60px;

                .instructionSetBarInside {
                    animation: progressBar 5s linear;
                    animation-iteration-count: 5;
                }
            }
        }

        .leftPane_three {
            display: flex;
            flex-direction: column;

            .instructionSetBar {
                margin-top: 0px;
            }

            .poseLikeThis {
                padding-bottom: 24px;
                font-family: "Sometimes Times";
                font-style: normal;
                font-weight: 400;
                font-size: 53px;
                line-height: 1.207547;
                letter-spacing: 0.1em;
                color: var(--klothed-textColor);
                margin-top: 8px;
            }

            .leftPane_frame {
                margin-top: 10px;
                width: 125px;
                align-self: center;
                margin: 0px 35px;
            }
        }

        .allSteps {
            flex-direction: row;
            justify-content: center;

            .allInstructions {
                display: flex;
                flex-direction: column;
                width: 190px;

                .allInstructionsLeftGp {
                    display: flex;
                    flex-direction: column;

                    .clrVerticaLine {
                        height: 1px;
                        width: 100%;
                        background: linear-gradient(90deg, #eaea15, #ff855f, #ff454e, #0031ff);
                        opacity: 0.7;
                    }

                    .allInsHeading {
                        font-family: "Sometimes Times";
                        font-style: normal;
                        font-weight: 400;
                        font-size: 28px;
                        line-height: 40px;
                        letter-spacing: 0.1em;
                        color: var(--klothed-textColor);
                        margin-top: 8px;
                    }

                    .allInsText {
                        font-family: "Commuters Sans";
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                        letter-spacing: 0.1em;
                        color: var(--klothed-textColor);
                        margin-top: 8px;
                    }
                }

                .allInstructionsLeftGpTwo {
                    margin-top: 70px;
                }

                .allInstructionsLeftGpOne {
                    margin-top: 160px;
                }
            }
        }
    }

    .upload_photo_right_pane {
        padding-right: 10vw;
        flex-basis: 33.6%;
        max-width: 33.6%;

        h2 {
            margin: 0 0 24px;
            padding-bottom: 16px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.5);
            font-family: "Sometimes Times";
            font-weight: 400;
            font-size: 28px;
            line-height: 40px;
            letter-spacing: 0.1em;
            color: var(--klothed-textColor);
        }

        .samplePhotosGroup {
            display: flex;
            flex-direction: row;
            margin-right: calc(10vw + -1px);
            margin-top: 85px;

            .samplePhotos {
                display: flex;
                flex-direction: column;
                align-items: center;

                .samplePhotosImg {
                    width: 289px;
                    height: 379px;
                }

                .samplePhotosCorrectImg {
                    border-radius: 6px 0px 0px 6px;
                }

                .samplePhotosWrongImg {
                    border-radius: 0px 6px 6px 0px;
                    opacity: 0.4;
                }

                .thumbsIcon {
                    margin-top: 20px;
                    height: 35px;
                    width: 35px;
                }
            }
        }

        .imageUploadDiv {
            position: relative;
            height: 500px;
            width: 375px;
            border: 3px dashed #ff855f;
            border-radius: 6px;
            display: flex;
            margin: 0 auto;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            h4 {
                font-family: "Commuters Sans";
                font-weight: 400;
                font-size: 16px;
                line-height: 1.5;
                text-align: center;
                letter-spacing: 0.1em;
                color: var(--klothed-textColor);
            }

            .imageUploadText {
                font-family: "Commuters Sans";
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                text-align: center;
                letter-spacing: 0.1em;
                color: var(--klothed-textColor);
            }

            .imageUploadInputBtn {
                width: 328px;
                height: 48px;
                background: #ff855f;
                border-radius: 6px;
                font-family: "Commuters Sans";
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                letter-spacing: 0.1em;
                text-transform: uppercase;
                color: var(--klothed-bgColor);
                margin-top: 16px;
            }

            .ip__ {
                width: 375px;
                height: 500px;
                position: absolute;
                opacity: 0;
                cursor: pointer;
            }

            .image_upload_close {
                position: absolute;
                top: 20px;
                right: 20px;
                border: 1px solid rgba(255, 255, 255, 0.5);
                border-radius: 6px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 5px;
                cursor: pointer;
            }

            .instructionSetBar {
                width: 328px;
                height: 2px;

                .instructionSetBarInside {
                    height: 2px;
                    animation: progressBar 5s infinite alternate;
                }
            }
        }

        .uploadedImageView {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .image_upload_close {
                border: 1px solid rgba(255, 255, 255, 0.5);
                border-radius: 6px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 5px;
                position: absolute;
                top: 20px;
                right: calc(10vw + 112px);
                cursor: pointer;
                background-color: var(--klothed-bgColor);
            }

            .uploadedImageViewImgDiv {
                position: relative;
                display: flex;
                flex-direction: column;

                .uploadedImageViewImgDivInside {
                    position: relative;
                    width: 375px;
                    height: 500px;
                    border-radius: 6px;
                    align-self: center;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .uploadedImageViewImg {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        border-radius: 6px;
                        object-fit: cover;
                    }

                    .uploadedImageViewImgGreyed {
                        opacity: 0.3;
                    }
                }

                .imageUploadText {
                    font-family: "Commuters Sans";
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    text-align: center;
                    letter-spacing: 0.1em;
                    color: var(--klothed-textColor);
                    white-space: break-spaces;
                    position: relative;
                    margin-bottom: -48px;
                    top: -280px;
                    width: 90%;
                    align-self: center;
                }

                .instructionSetBar {
                    width: 328px;
                    height: 2px;
                    align-self: center;
                    position: relative;
                    bottom: 245px;

                    .instructionSetBarInside {
                        height: 2px;
                        animation: progressBar 5s infinite alternate;
                    }
                }

                .invalidImageText {
                    font-family: "Commuters Sans";
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    text-align: center;
                    letter-spacing: 0.1em;
                    color: var(--klothed-textColor);
                    white-space: break-spaces;
                    position: relative;
                    margin-bottom: -48px;
                    top: -280px;
                    width: 90%;
                    align-self: center;
                }
            }

            .moveNextStep {
                width: 328px;
                height: 48px;
                background: #ff855f;
                border-radius: 6px;
                font-family: "Commuters Sans";
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                letter-spacing: 0.1em;
                text-transform: uppercase;
                color: var(--klothed-bgColor);
                margin-top: 56px;
                cursor: pointer;
            }

            .moveNextStepDisable {
                cursor: none;
                pointer-events: none;
                opacity: 0.3;
            }
        }
    }
}
