.column-boxes {
    display: flex;
    align-items: center;
    gap: 1%;
    padding: 0 56px;
    margin-bottom: 64px;
    color: var(--klothed-textColor);

    @media (max-width: 980px) {
        gap: 0;
        flex-wrap: wrap;
    }

    @media (max-width: 767px) {
        position: relative;
        padding: 0 0 64px;
    }

    &.justify-end {
        justify-content: flex-end;
    }

    &.no-padding {
        padding: 0;
    }

    &:after {
        @media (max-width: 767px) {
            content: "";
            position: absolute;
            bottom: 0;
            left: 50%;
            width: 100vw;
            height: 1px;
            transform: translateX(-50%);
            background-color: var(--klothed-textColor);
            opacity: 0.5;
        }
    }

    .column-box {
        flex: 1 1 48%;
        max-width: 50%;

        @media (max-width: 980px) {
            flex: 0 0 100%;
            max-width: 100%;
        }

        h3 {
            margin: 13px 0 0;
            font-size: 53px;
            line-height: 1.207547;
            font-family: "Sometimes Times";
            font-weight: normal;
            letter-spacing: 0.1em;

            @media (max-width: 980px) {
                margin-bottom: 64px;
            }

            @media (max-width: 767px) {
                margin-top: 7px;
                font-size: 28px;
                line-height: 1.428571;
            }

            span {
                background: linear-gradient(90deg, #eaea15 2.23%, #ff855f 37.24%, #ff454e 64.39%, #be0edb 92.69%, #ad00ff 98.91%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                box-decoration-break: clone;
                -webkit-box-decoration-break: clone;
            }
        }

        .button {
            margin-top: 56px;
        }
    }

    .column-box__text {
        &.border-left {
            position: relative;
            &:after {
                content: "";
                position: absolute;
                width: 100vw;
                height: 1px;
                top: 0;
                right: 100%;
                background-color: var(--klothed-textColor);
                opacity: 0.5;
            }

            img {
                border-radius: 0 6px 6px 6px;
            }
        }
    }

    .box-image {
        display: flex;
        height: 575px;
        margin-bottom: 32px;
        padding: 0 60px;
        border: 1px solid rgba(255, 255, 255, 0.5);
        border-radius: 6px;
        min-width: 348px;

        @media (max-width: 980px) {
            padding: 0 10px;
            border: 0;
            height: auto;
            justify-content: center;
        }

        @media (max-width: 767px) {
            min-width: 100%;
            margin-bottom: 20px;
            padding-left: 5px;
        }

        &.align-center {
            align-items: center;
        }

        &.justify-center {
            justify-content: center;
        }

        &.align-flex-end {
            align-items: flex-end;
        }

        &.justify-flex-end {
            justify-content: flex-end;
        }

        &.align-flex-start {
            align-items: flex-start;
        }

        &.justify-flex-start {
            justify-content: flex-start;
        }

        &.justify-center,
        &.justify-flex-end,
        &.justify-flex-start {
            @media (max-width: 980px) {
                justify-content: center;
            }
        }

        &.constrict {
            @media (max-width: 767px) {
                max-width: 60%;
                min-width: 60%;
                margin-left: auto;
                margin-right: auto;
            }

            img {
                @media (max-width: 767px) {
                    width: auto;
                    height: auto;
                    max-width: 100%;
                }
            }
        }

        img {
            @media (max-width: 767px) {
                width: auto;
                height: auto;
                max-width: 100%;
            }
        }

        &.img1 {
            img {
                border-radius: 0 0 6px 6px;

                @media (max-width: 980px) {
                    border-radius: 6px;
                }
            }
        }

        &.img3 {
            @media (max-width: 980px) {
                padding: 0;
                justify-content: flex-end;
                margin-right: -24px;
            }

            img {
                border-radius: 6px 6px 0 0;

                @media (max-width: 980px) {
                    border-radius: 6px 0 0 6px;
                }
            }
        }
    }

    .column-box__image {
        max-width: 575px;

        @media (max-width: 980px) {
            max-width: 100%;
        }

        p {
            margin: 0;
            font-size: 20px;
            line-height: 1.6;
            text-transform: uppercase;
            letter-spacing: 0.1em;
            font-family: "Commuters Sans";
            font-weight: 400;

            @media (max-width: 767px) {
                font-size: 14px;
                line-height: 1.428571;
            }

            span.colored-text {
                background: linear-gradient(90deg, #eaea15 2.23%, #ff855f 37.24%, #ff454e 64.39%, #be0edb 92.69%, #ad00ff 98.91%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                box-decoration-break: clone;
                -webkit-box-decoration-break: clone;
            }

            .text {
                @media (max-width: 767px) {
                    display: block;
                }
            }
        }
    }
}
