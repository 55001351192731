.team-landing {
    @media (max-width: 767px) {
        position: relative;
    }

    .footer {
        @media (max-width: 767px) {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            z-index: 9;
        }

        p {
            @media (max-width: 767px) {
                color: var(--klothed-bgColor);
            }
        }
    }
}

.team-wrapper {
    overflow: hidden;
    margin-bottom: 60px;

    @media (max-width: 767px) {
        margin-bottom: 0;
        padding-top: 75px;
    }
}

.team-image-row {
    &.margin-top {
        margin-top: 136px;

        @media (max-width: 767px) {
            margin-top: 63px;
        }
    }

    .team-image-row__inner {
        display: flex;
        color: var(--klothed-textColor);
        gap: 38px;

        @media (max-width: 980px) {
            flex-wrap: wrap;
            gap: 0;
        }

        &.align-center {
            align-items: center;
        }
    }

    .left-column,
    .right-column {
        flex: 0 1 43.6783%;

        @media (max-width: 980px) {
            flex: 1 1 100%;
        }
    }

    .mobile-order {
        @media (max-width: 980px) {
            order: 1;
        }
    }

    img {
        display: block;
        max-width: 100%;
        height: auto;

        &.br-6-6-0-6 {
            border-radius: 6px 6px 0 6px;
        }

        &.br-0-0-6-0 {
            border-radius: 0 0 6px 0;

            @media (max-width: 980px) {
                border-radius: 0 6px 6px 0;
            }
        }

        &.mobile-left-side {
            @media (max-width: 767px) {
                margin-left: -24px;
                max-width: 64%;
            }
        }

        &.mobile-flush {
            @media (max-width: 767px) {
                margin-left: -24px;
                width: calc(100% + 24 * 2px);
                max-width: calc(100% + 24 * 2px);
                height: auto;
                border-radius: 0;
            }
        }
    }

    h5 {
        margin-bottom: 56px;
        font-size: 20px;
        line-height: 1.6;
        font-weight: normal;
        font-family: "Commuters Sans";
        letter-spacing: 0.1em;

        @media (max-width: 767px) {
            margin: 0 0 24px;
            font-size: 14px;
            line-height: 1.428571;
        }
    }

    h3 {
        margin: 0;
        font-size: 53px;
        line-height: 1.207547;
        font-family: "Sometimes Times";
        font-weight: normal;
        letter-spacing: 0.1em;

        @media (max-width: 767px) {
            font-size: 28px;
            line-height: 1.428571;
        }

        span {
            background: linear-gradient(90deg, #eaea15 2.23%, #ff855f 37.24%, #ff454e 64.39%, #be0edb 92.69%, #ad00ff 98.91%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            box-decoration-break: clone;
            -webkit-box-decoration-break: clone;
        }
    }

    .border-box {
        position: relative;
        padding: 65px;
        border: 1px solid rgba(255, 255, 255, 0.5);
        border-radius: 0px 6px 6px 0px;
        max-width: 456px;

        @media (max-width: 980px) {
            padding: 32px;
            margin-bottom: 144px;
            max-width: 100%;
        }

        .button {
            padding: 0 2px;
            @media (max-width: 980px) {
                position: absolute;
                top: 100%;
                left: 0;
                width: 100%;
                transform: translateY(20px);
            }
            .button__inner {
                flex: 0 0 100%;
            }
        }
    }

    .align-flex-end {
        align-items: flex-end;
    }

    .relative {
        position: relative;
        z-index: 2;
    }

    .line {
        position: absolute;
        background-color: var(--klothed-textColor);
        opacity: 0.5;

        &.left-top {
            bottom: 100%;
            left: -1px;
            width: 1px;
            height: 100vw;

            @media (max-width: 980px) {
                right: 100%;
                top: -1px;
                bottom: auto;
                left: auto;
                height: 1px;
                width: 100%;
            }
        }

        &.right-bottom {
            bottom: -1px;
            right: 100%;
            width: 50%;
            height: 1px;

            @media (max-width: 980px) {
                display: none;
            }
        }
    }

    .show-mobile {
        display: none;
        @media (max-width: 980px) {
            display: inline-flex;
        }
    }

    .hide-mobile {
        @media (max-width: 980px) {
            display: none;
        }
    }
}
