.resetPassword {
    min-width: 1024px;
    .klothed_logo {
        height: 54px;
        width: 182px;
        position: fixed;
        top: 4.5%;
        left: 5vw;
        cursor: pointer;
        z-index: 1;

        @media (max-width: 980px) {
            width: auto;
            max-height: 26px;
            top: 13px;
        }
    }

    .rightSliderLine {
        display: flex;
        flex-direction: column;
        position: fixed;
        top: 4.5%;
        right: 5vw;
        cursor: pointer;

        .rightSliderLine_ {
            width: 32px;
            height: 2px;
            background-color: var(--klothed-textColor);
            margin: 6px 0px;
        }
    }

    .rightSliderOverlay {
        position: fixed;
        top: 0px;
        left: 0px;
        min-height: 100vh;
        width: 100vw;
    }

    .footer_section {
        position: fixed;
        left: 5vw;
        bottom: 3vh;
        font-family: "Commuters Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        color: var(--klothed-textColor);
    }

    .email_enterDiv {
        position: fixed;
        height: 536px;
        width: 576px;
        left: calc(50vw - 288px);
        top: calc(50vh - 268px);
        border: 1px solid rgba(255, 255, 255, 0.5);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 6px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;

        .email_enterDivText {
            font-family: "Sometimes Times";
            font-weight: 400;
            font-size: 28px;
            letter-spacing: 0.1em;
            color: var(--klothed-textColor);
        }

        .email_input_div,
        .password_input_div {
            position: relative;
            margin: 16px 0px;

            .email_input_label,
            .password_input_label {
                position: absolute;
                font-size: 1rem;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                background-color: var(--klothed-bgColor);
                color: gray;
                margin-left: 15px;
                padding: 0px 5px;
                transition: 0.2s ease-out;
                transform-origin: left top;
                pointer-events: none;
                font-family: "Commuters Sans";
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.1em;
                color: rgba(255, 255, 255, 0.7);
            }

            .email_input_box,
            .password_input_box {
                height: 48px;
                width: 306px;
                border: 1px solid rgba(255, 255, 255, 0.5);
                border-radius: 6px;
                outline: none;
                font-family: "Commuters Sans";
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.1em;
                color: var(--klothed-textColor);
                background-color: var(--klothed-bgColor);
                padding: unset;
                padding-left: 20px;
            }

            .email_input_box:focus,
            .password_input_box:focus {
                border-color: #ff855f;
            }

            .email_input_box:focus + .email_input_label,
            .password_input_box:focus + .password_input_label {
                color: #ff855f;
                top: 0;
                transform: translateY(-40%) translateX(-2%) scale(0.9);
            }

            .email_input_box:not(:placeholder-shown) + .email_input_label,
            .password_input_box:not(:placeholder-shown) + .password_input_label {
                top: 0;
                transform: translateY(-40%) translateX(-2%) scale(0.9);
            }

            .showPasswordIMG {
                position: absolute;
                right: 10px;
                bottom: 17px;
                cursor: pointer;
            }
        }

        .password_input_div1 {
            margin-top: -15px;
        }

        .password_input_div2 {
            margin-top: -30px;
        }

        .email_enterDivSubmit {
            height: 48px;
            width: 328px;
            background: #ff855f;
            border-radius: 6px;
            font-family: "Commuters Sans";
            font-weight: 600;
            font-size: 14px;
            display: flex;
            justify-content: center;
            align-items: center;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            color: var(--klothed-bgColor);
            pointer-events: none;
            opacity: 0.3;
        }

        .email_enterDivSubmitActive {
            pointer-events: initial;
            opacity: 1;
            cursor: pointer;
        }

        .email_enterDivTextLower {
            font-family: "Commuters Sans";
            font-weight: 400;
            font-size: 16px;
            letter-spacing: 0.1em;
            color: var(--klothed-textColor);
            white-space: break-spaces;
        }

        .passwordSuggestion {
            font-family: "Commuters Sans";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.1em;
            color: var(--klothed-textColor);
            width: 328px;
        }
    }

    .resetPasswordSuccess {
        position: fixed;
        width: 576px;
        height: 384px;
        left: calc(50vw - 288px);
        top: calc(50vh - 192px);
        background: var(--klothed-bgColor);
        border: 1px solid rgba(255, 255, 255, 0.5);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 6px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;

        .resetPasswordSuccessText {
            font-family: "Sometimes Times";
            font-style: normal;
            font-weight: 400;
            font-size: 28px;
            line-height: 40px;
            text-align: center;
            letter-spacing: 0.1em;
            color: var(--klothed-textColor);
            width: 328px;
        }

        .logIn_wrapper {
            background: linear-gradient(90deg, #eaea15 2.23%, #ff855f 29.99%, #ff454e 51.12%, #0031ff 96.38%);
            width: 328px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 8px 0px;
            border-radius: 6px;

            .logIn {
                border-radius: 6px;
                width: 324px;
                height: 46px;
                font-family: "Commuters Sans";
                background-color: var(--klothed-bgColor);

                font-weight: 600;
                font-size: 14px;
                display: flex;
                align-items: center;
                justify-content: center;
                text-transform: uppercase;
                color: white;
                cursor: pointer;
            }
        }
    }
}
