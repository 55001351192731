.catalogProductsItem {
    width: var(--klothed-tile-width);

    .catalogProductsItemInside {
        border-radius: 6px;

        .catalogProductsItemImage {
            width: var(--klothed-tile-width);
            height: var(--klothed-tile-height);
            position: relative;
            background-color: var(--klothed-tile-bgColor);
            border-radius: 6px;

            .grayscale {
                filter: grayscale(80%);
            }
            .catalogProductsCustomerImage {
                border-radius: 6px;
                height: 100%;
                object-fit: scale-down;
                max-width: 100%;
            }

            .favoriteIcon_ {
                position: absolute;
                bottom: 0;
                left: 0;
                padding: 16px;
                width: 48px;
                height: 48px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
            }
        }
    }

    .catalogProductsItemInside:hover {
        border-color: #ff855f;
    }

    .itemShopDetails {
        text-decoration: none;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;

        width: 100%;
        margin-top: 16px;
        margin-bottom: 16px;
        &:active {
            color: var(--klothed-textColor);
        }
        .itemShopDetailsShopNow {
            margin: 0 auto 12px auto;
            display: flex;
            gap: 4px;
            flex-direction: column;
            span {
                font-family: "Roboto", sans-serif;
                font-weight: 600;
                font-size: 12px;
                line-height: 14px;
                overflow: hidden;
                white-space: nowrap;
                letter-spacing: 0.1em;
            }
        }
        .itemShopDetailsProduct {
            width: 100%;
            display: flex;
            align-items: center;
            flex-direction: column;

            .itemShopDetailsBrand,
            .itemShopDetailsRetailer {
                font-family: "Roboto", sans-serif;
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                height: 16px;
                text-align: center;
                width: 100%;
                border: none;
                letter-spacing: 0.1em;
                color: var(--klothed-textColor-dark70);
                display: block;
                align-items: center;
                justify-content: center;
            }

            .itemShopDetailsBrand {
                margin-bottom: 2px;
                color: var(--klothed-textColor);
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
        }
    }

    .itemTryonToggle {
        margin-top: 16px;
        /* Button/Icon Only/Hover */

        box-sizing: border-box;

        /* Auto layout */

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px;
        gap: 10px;

        width: var(--klothed-tile-width);
        height: 32px;

        /* black 100% */

        background: var(--klothed-bgColor);
        border: var(--klothed-textBorder-width) solid var(--klothed-textColor);
        border-radius: 6px;

        user-select: none;

        cursor: pointer;
        span {
            display: flex;
            align-items: center;
            font-family: "Commuters Sans";
            font-style: normal;
            font-weight: 600;
            font-size: 11px;
            line-height: 16px;
        }
    }
}

#popup-root > div {
    background-color: var(--klothed-bgColor);
    .close {
        position: fixed;
        top: 0;
        left: 0;
        cursor: pointer;
        z-index: 1;
        > svg {
            display: flex;
            align-items: center;
            max-width: 1310px;
            margin: 0 auto;
            padding: 24px 64px;

            &.smaller {
                max-width: 1200px;
            }

            @media (max-width: 1200px) {
                padding: 24px;
            }
        }
    }
    .tile-detail {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--klothed-bgColor);
        color: var(--klothed-textColor);
        word-break: break-all;
        flex-direction: column;
        width: fit-content;
        overflow-y: auto;

        .content {
            margin-top: 32px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            aspect-ratio: 375 / 712;
            position: relative;
            background-color: rgba(0, 0, 0, 0);
            height: min(50vh, 712px);
            > img {
                width: 100%;
                height: 100%;
            }
            .favoriteIcon_ {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 48px;
                height: 48px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
            }
            .contextDots {
                position: absolute;
                top: 16px;
                right: 16px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .grayscale {
                filter: grayscale(80%);
            }
        }
        .sub-details {
            display: flex;
            align-items: center;
            width: 286px;
            justify-content: center;
            flex-direction: column-reverse;
            .itemShop {
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                a:focus-visible {
                    outline: none;
                }

                .itemShopDetails {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    display: flex;
                    flex-direction: column;
                    padding: 0px;
                    text-decoration: none;
                    margin-bottom: 8px;
                    color: var(--klothed-textColor);

                    &:active {
                        color: var(--klothed-textColor);
                    }
                    .itemShopDetailsShopNow {
                        background: linear-gradient(90deg, #eaea15 2.23%, #ff855f 29.99%, #ff454e 51.12%, #0031ff 96.38%);
                        border-radius: 6px;
                        width: 100%;
                        height: 48px;
                        font-family: "Commuters Sans";
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 20px;
                        letter-spacing: 0.1em;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        text-transform: uppercase;
                        color: var(--klothed-tile-bgColor);
                        margin-bottom: 16px;
                        cursor: pointer;
                        user-select: none;
                    }
                    .itemShopDetailsProduct {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                        margin-top: 28px;
                        margin-bottom: 24px;
                        .itemShopDetailsBrand,
                        .itemShopDetailsType,
                        .itemShopDetailsRetailer {
                            font-family: "Roboto", sans-serif;
                            font-style: normal;
                            font-size: 16px;
                            line-height: 24px;
                            height: 24px;
                            /* identical to box height, or 117% */
                            text-align: center;
                            letter-spacing: 0.1em;
                            width: 262px;

                            /* Inside auto layout */

                            flex: none;
                            flex-grow: 0;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            white-space: nowrap;
                        }

                        .itemShopDetailsType,
                        .itemShopDetailsType:visited,
                        .itemShopDetailsType:active {
                            color: var(--klothed-textColor-dark70);
                        }
                    }
                }

                .itemTryonToggle {
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    padding: 8px;
                    gap: 10px;

                    width: 100%;
                    height: 48px;

                    /* black 100% */

                    background: var(--klothed-bgColor);
                    border: var(--klothed-textBorder-width) solid var(--klothed-textColor);
                    border-radius: 6px;

                    user-select: none;

                    margin-bottom: 24px;

                    cursor: pointer;
                    span {
                        display: flex;
                        align-items: center;
                        font-family: "Commuters Sans";
                        font-style: normal;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 20px;
                        letter-spacing: 0.1em;
                    }
                }
                p {
                    margin: 12px auto 6px auto;
                }
            }
            .itemShareTryOn {
                font-family: "Roboto";
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 14px;
                /* identical to box height, or 117% */

                text-align: center;
                letter-spacing: 0.1em;
                width: fit-content;
                margin: 0 auto;
                cursor: pointer;
                user-select: none;
            }
        }
    }
    .zoom {
        .content {
            margin-top: 0;
            height: 100vh;
            width: 100vw;
            > img {
                aspect-ratio: 375 / 712;
                width: 100%;
                height: 100%;
                object-fit: scale-down;
            }
            .favoriteIcon_ {
                display: none;
            }
            .contextDots {
                display: none;
            }
            .grayscale {
                filter: grayscale(80%);
            }
            .iiz__close {
                background-color: rgba(0, 0, 0, 0);
            }
            .iiz__hint {
                display: none;
            }
        }
        .sub-details {
            display: none;
        }
    }
}
