.how-it-works {
    .heading-line {
        margin-bottom: 48px;

        @media (max-width: 980px) {
            margin-bottom: -10px;
        }

        .heading-line__item:after {
            bottom: 22px;
        }
    }

    .two-columns {
        margin-bottom: 45px;

        @media (max-width: 980px) {
            margin-bottom: 40px;
        }

        h5 {
            margin-bottom: 32px;

            @media (max-width: 980px) {
                margin-bottom: 20px;
                font-size: 14px;
                line-height: 1.428571;
            }
        }

        .column--text {
            @media (max-width: 980px) {
                padding-bottom: 0;
            }
        }
    }
}
