.policy-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 21px 20px;
    background-color: var(--klothed-textColor);
    z-index: 99999; // above toastify

    @media (max-width: 980px) {
        padding-left: 50px;
        padding-right: 50px;
    }

    .container {
        max-width: 1314px;
        margin: 0 auto;
    }

    .policy-bar__col,
    .policy-bar__inner {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (max-width: 980px) {
            display: block;
            text-align: center;
        }
    }

    p {
        font-size: 12px;
        line-height: 1.3;
        color: var(--klothed-bgColor);
        font-family: "Commuters Sans";
        letter-spacing: 0.1em;

        a {
            color: #ff855f;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .policy-bar__close {
        background: none;
        border: 0;
        appearance: none;
        margin-left: 48px;
        cursor: pointer;
        transition: transform 0.3s;

        @media (hover: hover) {
            &:hover {
                transform: rotate(90deg);
            }
        }

        @media (max-width: 980px) {
            position: absolute;
            right: 5px;
            top: 15px;
        }
    }
}
