.user_profile_height_style {
    display: flex;
    margin-top: 95px;
    padding-left: 24px;
    padding-right: 24px;
    min-height: calc(100vh - 380px);
    max-width: 393px;
    width: calc(100vw - (100vw - 100%));
    align-self: center;
    .artifact_group {
        display: flex;
        width: 100%;
        height: 100%;
        .profile_height_style_section_v2 {
            display: flex;
            flex-direction: column;
            letter-spacing: 0.1em;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 24px;
            max-width: 400px;
            margin: 0 auto;
            width: 100%;

            form {
                display: flex;
                flex-direction: column;
                width: 100%;
                flex-grow: 1;
                input[type="submit"] {
                    margin-top: auto;
                }
            }

            .height,
            .style {
                margin-bottom: 56px;
            }
            h2 {
                font-family: "Commuters Sans";
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 28px;
                /* identical to box height, or 140% */

                letter-spacing: 0.1em;

                /* var(--klothed-textColor) 100% */

                color: var(--klothed-textColor);
                margin: 0 0 24px 0;
            }
            .height > span,
            .style > span {
                font-family: "Commuters Sans";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                /* or 143% */

                display: flex;
                align-items: center;
                letter-spacing: 0.1em;
                margin: 0 0 24px 0;
            }
            .height {
                .height-group {
                    display: flex;
                    width: fit-content;
                    border: 1px solid var(--klothed-textColor-trans50);
                    border-radius: 6px;
                    align-items: center;
                    padding: 14px 12px;
                    label {
                        font-family: "Commuters Sans";
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 20px;
                        display: flex;
                        align-items: center;
                        letter-spacing: 0.1em;
                        color: var(--klothed-textColor-trans70);
                        margin-left: 10px;
                        &:first-of-type {
                            margin-right: 56px;
                        }
                    }
                    input::-webkit-outer-spin-button,
                    input::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                    }

                    /* Firefox */
                    input[type="number"] {
                        -moz-appearance: textfield;
                        appearance: textfield;
                        background-color: rgba(0, 0, 0, 0);
                        border: none;
                        text-align: right;
                        width: 2ch;

                        font-family: "Commuters Sans";
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 20px;
                        display: flex;
                        align-items: center;
                        letter-spacing: 0.1em;
                        color: var(--klothed-textColor);
                        &:focus {
                            outline: none;
                        }
                    }
                }
            }
            .style-selection {
                .style-group {
                    color: var(--klothed-textColor);
                    margin-top: 24px;
                    margin-bottom: 56px;
                }

                .form-group-radio {
                    display: flex;
                    flex-direction: column;
                    gap: 6px;
                    .radio-field {
                        display: flex;
                        flex-direction: row;
                        position: relative;
                        label {
                            display: flex;
                            align-items: center;
                            p {
                                margin-left: 16px;
                                font-family: "Commuters Sans";
                                font-style: normal;
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 20px;
                                letter-spacing: 0.1em;
                            }
                        }
                    }

                    & input[type="radio"] {
                        opacity: 0;
                        width: 0;
                        position: absolute;
                    }
                    & input[type="radio"] + label {
                        cursor: pointer;
                    }

                    & input[type="radio"] + label span {
                        display: inline-block;
                        vertical-align: middle;
                        text-align: center;
                    }

                    & input[type="radio"] + label > span {
                        border-radius: 50%;
                        width: 32px;
                        height: 32px;
                        margin: 0;
                        border: 1px solid var(--klothed-textColor-dark20);
                    }

                    & input[type="radio"] + label > span > span {
                        border-radius: 50%;
                        width: 16px;
                        height: 16px;
                        margin: 7px;
                        border: none;
                        transition: all 0.3s ease;
                    }

                    & input[type="radio"]:checked + label > span > span {
                        opacity: 1;
                        background-color: var(--klothed-textColor);
                    }
                }
            }
            input[type="submit"] {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;

                width: 100%;
                height: 48px;
                background: var(--klothed-textColor);
                border-radius: 6px;

                font-family: "Commuters Sans";
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                /* identical to box height, or 143% */

                display: flex;
                align-items: center;
                text-align: center;
                letter-spacing: 0.1em;
                text-transform: uppercase;

                color: var(--klothed-bgColor);

                cursor: pointer;
            }
        }
    }
}
