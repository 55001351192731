.settingsHomePage {
    display: flex;
    flex-direction: row;
    min-height: 100vh;
    justify-content: center;
    align-items: center;

    .settingsHomePage_headerText {
        position: fixed;
        right: 5vw;
        text-transform: capitalize;
        top: calc(6vh + 75px);
        font-family: "Commuters Sans";
        font-weight: 400;
        font-size: 16px;
        letter-spacing: 0.1em;
        color: var(--klothed-textColor);
        z-index: 3;
    }

    .delete_overlay {
        position: fixed;
        top: 0px;
        left: 0px;
        height: 100vh;
        width: 100vw;
        background: rgba(0, 0, 0, 0.7);
        z-index: 4;
    }

    .delete_account {
        width: 576px;
        height: 308px;
        position: fixed;
        top: calc(50vh - 154px);
        left: calc(50vw - 288px);
        border: 1px solid rgba(255, 255, 255, 0.5);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 6px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        z-index: 5;

        .delete_account_closeDiv {
            border: 1px solid rgba(255, 255, 255, 0.5);
            border-radius: 6px;
            height: 24px;
            width: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            align-self: flex-end;
            margin-right: 30px;
        }

        .delete_account_text {
            font-family: "Commuters Sans";
            font-weight: 400;
            font-size: 16px;
            letter-spacing: 0.1em;
            color: var(--klothed-textColor);
        }

        .delete_account_BtnDiv {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin-bottom: 10px;

            .delete_account_Btn {
                width: 184px;
                height: 48px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-family: "Commuters Sans";
                font-weight: 600;
                font-size: 14px;
                letter-spacing: 0.1em;
                text-transform: uppercase;
                color: #ff855f;
                border: 2px solid #ff855f;
                border-radius: 6px;
                margin: 0px 30px;
                cursor: pointer;
            }

            .delete_account_BtnNo {
                color: black;
                background-color: #ff855f;
            }

            .delete_account_BtnInactive {
                opacity: 0.3;
                pointer-events: none;
                cursor: none;
            }
        }

        .email_input_div,
        .password_input_div {
            position: relative;
            margin: 16px 0px;

            .email_input_label,
            .password_input_label {
                position: absolute;
                font-size: 1rem;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                background-color: var(--klothed-bgColor);
                color: gray;
                margin-left: 15px;
                padding: 0px 5px;
                transition: 0.2s ease-out;
                transform-origin: left top;
                pointer-events: none;
                font-family: "Commuters Sans";
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.1em;
                color: rgba(255, 255, 255, 0.7);
            }

            .email_input_box,
            .password_input_box {
                height: 48px;
                width: 306px;
                border: 1px solid rgba(255, 255, 255, 0.5);
                border-radius: 6px;
                outline: none;
                font-family: "Commuters Sans";
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.1em;
                color: var(--klothed-textColor);
                background-color: var(--klothed-bgColor);
                padding: unset;
                padding-left: 20px;
            }

            .email_input_box:focus,
            .password_input_box:focus {
                border-color: #ff855f;
            }

            .email_input_box:focus + .email_input_label,
            .password_input_box:focus + .password_input_label {
                color: #ff855f;
                top: 0;
                transform: translateY(-40%) translateX(-2%) scale(0.9);
            }

            .email_input_box:not(:placeholder-shown) + .email_input_label,
            .password_input_box:not(:placeholder-shown) + .password_input_label {
                top: 0;
                transform: translateY(-40%) translateX(-2%) scale(0.9);
            }

            .showPasswordIMG {
                position: absolute;
                right: 10px;
                bottom: 17px;
                cursor: pointer;
            }
        }
    }

    .settingsHomePage_leftPane {
        width: 50vw;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .settingsHomePage_leftPaneFixed {
            position: fixed;
            left: 5vw;
            top: calc(50vh - 210px);

            .settingsHomePage_leftPane_IconGrp {
                display: flex;
                flex-direction: row;
                margin: 8px 0px;
                width: max-content;
                cursor: pointer;

                .settingsHomePage_leftPane_IconDiv {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 48px;
                    height: 48px;
                    border: 1px solid rgba(255, 255, 255, 0.5);
                    border-radius: 6px;
                    margin-right: 16px;
                }

                .settingsHomePage_leftPane_IconDivSelected {
                    border: 1px solid #ff855f;
                    background: rgba(255, 133, 95, 0.5);
                }

                .settingsHomePage_leftPane_IconValue {
                    font-family: "Commuters Sans";
                    font-weight: 400;
                    font-size: 16px;
                    letter-spacing: 0.1em;
                    color: var(--klothed-textColor);
                    align-self: center;
                    display: flex;
                    flex-direction: row;
                }
            }

            .settingsHomePage_leftPane_horizontalSplit {
                height: 1px;
                width: 100%;
                background-color: rgba(255, 255, 255, 0.5);
                width: 330px;
            }

            .settingsHomePage_leftPane_horizontalSplitTop {
                margin-top: 40px;
            }

            .settingsHomePage_leftPane_horizontalSplitBottom {
                margin-bottom: 40px;
            }

            .settingsHomePageIconGrp {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-around;
                margin: 16px 0px;
                width: 330px;

                .settingsHomePageIconGrpImg {
                    cursor: pointer;
                }
            }

            .logOutBtn {
                width: 328px;
                height: 48px;
                border: 2px solid #ff855f;
                border-radius: 6px;
                font-family: "Commuters Sans";
                font-weight: 600;
                font-size: 14px;
                display: flex;
                align-items: center;
                justify-content: center;
                letter-spacing: 0.1em;
                text-transform: uppercase;
                color: #ff855f;
                cursor: pointer;
            }
        }
    }

    .settingsHomePage_rightPane {
        width: 50vw;
        min-height: 100vh;

        .blackbox {
            position: fixed;
            height: 20vh;
            top: 0px;
            right: 0px;
            background: black;
            width: 50vw;
        }

        .settingsLegal {
            margin: 15vh 5vw 10vh 0px;
            z-index: -2;

            .leftTop_heading,
            .horizonta_seperator_legal {
                display: none;
            }
        }

        .notificationsClass {
            display: flex;
            flex-direction: column;
            color: var(--klothed-textColor);
            position: fixed;
            top: calc(6vh + 75px + 96px);
            width: 45vw;

            .notificationEmailHeading {
                font-family: "Sometimes Times";
                font-style: normal;
                font-weight: 400;
                font-size: 28px;
                line-height: 40px;
                letter-spacing: 0.1em;
            }

            .notificationEmailHeading1 {
                margin-top: 24px;
                font-family: "Commuters Sans";
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.1em;
            }

            .toggleKnob {
                margin-top: 24px;
                width: 64px;
                height: 32px;
                background: var(--klothed-bgColor);
                border: 1px solid rgba(255, 255, 255, 0.5);
                border-radius: 50px;
                display: flex;
                align-items: center;
                cursor: pointer;

                .toggleKnobSwitch {
                    width: 26px;
                    height: 26px;
                    background-color: var(--klothed-textColor);
                    border-radius: 100%;
                    margin: 0px 3px;
                }
            }

            .toggleKnob.toggleKnobActive {
                background: linear-gradient(90deg, #eaea15 2.23%, #ff855f 29.99%, #ff454e 51.12%, #0031ff 96.38%);
                justify-content: end;
            }

            .notificationEmailHeading2 {
                margin-top: 24px;
                font-family: "Commuters Sans";
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                letter-spacing: 0.1em;
                color: var(--klothed-textColor);
            }

            .horizontalSep {
                height: 1px;
                width: 100%;
                margin-top: 24px;
                background-color: rgba(255, 255, 255, 0.5);
            }

            .notificationEmailHeading3 {
                margin-top: 24px;
                font-family: "Commuters Sans";
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.1em;
            }

            .notificationEmailHeading4 {
                margin-top: 24px;
                font-family: "Commuters Sans";
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 20px;
                letter-spacing: 0.1em;
            }

            .notificationEmailHeading5 {
                margin-top: 24px;
                font-family: "Sometimes Times";
                font-style: normal;
                font-weight: 400;
                font-size: 28px;
                line-height: 40px;
                letter-spacing: 0.1em;
            }
        }
    }

    .blackboxHelp {
        position: fixed;
        height: 20vh;
        top: 0px;
        right: 0px;
        background: black;
        width: 50vw;
    }

    .settingsHelp {
        .klothed_logo,
        .footer_section,
        .leftTop_heading,
        .horizonta_seperator_legal,
        .rightSliderLine {
            display: none;
        }

        .help_left_section {
            width: 40vw;
            margin: 12vh calc(4vw + 4px) 100px 5vw;

            .leftFixedSection {
                z-index: 1;
            }
        }

        .help_right_section {
            width: 45vw;
            margin: 16vh 5vw 100px 0vw;
        }
    }

    .goToSettings {
        position: fixed;
        top: calc(6vh + 75px);
        left: 5vw;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 163px;
        height: 32px;
        border: 1px solid rgba(255, 255, 255, 0.5);
        border-radius: 6px;
        font-family: "Commuters Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 16px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: var(--klothed-textColor);
        gap: 10px;
        cursor: pointer;
        z-index: 2;
    }
}
