.button {
    background: linear-gradient(90deg, #eaea15 2.23%, #ff855f 29.99%, #ff454e 51.12%, #0031ff 96.38%);
    width: 328px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 8px 0px;
    padding: 0 2px;
    border-radius: 6px;
    text-decoration: none;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: var(--klothed-textColor);

    @media (max-width: 767px) {
        display: flex;
        width: auto;
        align-items: center;
    }

    .button__inner {
        z-index: 1;
        border-radius: 6px;
        width: 100%;
        height: 46px;
        font-family: "Commuters Sans";
        background-color: var(--klothed-tile-textColor);
        font-weight: 600;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        color: var(--klothed-tile-bgColor);
        transition: background 0.3s;

        @media (max-width: 767px) {
            display: flex;
            flex: 1 1 100%;
        }
    }

    &:hover {
        .button__inner {
            background: transparent;
        }
    }

    &--white {
        .button__inner {
            background-color: var(--klothed-textColor);
            color: var(--klothed-bgColor);
        }
    }

    &--fill {
        .button__inner {
            background: transparent;
        }

        &:hover {
            .button__inner {
                background-color: var(--klothed-bgColor);
                color: var(--klothed-textColor);
            }
        }
    }
}
