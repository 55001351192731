.auto-tab {
    margin-bottom: 63px;

    @media (max-width: 980px) {
        position: relative;
        margin-bottom: 0;
        padding-bottom: 38px;
        margin-bottom: 60px;
    }

    &:after {
        @media (max-width: 980px) {
            content: "";
            position: absolute;
            bottom: 0;
            left: 50%;
            height: 1px;
            width: 100vw;
            background-color: var(--klothed-textColor);
            opacity: 0.5;
            transform: translateX(-50%);
        }
    }

    .auto-tab__inner {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        @media (max-width: 980px) {
            flex-wrap: wrap;
        }
    }

    h3 {
        margin: 0 0 55px;
        font-family: "Commuters Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 40px;
        line-height: 1.6;
        color: var(--klothed-textColor);
        text-transform: uppercase;
        letter-spacing: 0.1em;

        @media (max-width: 980px) {
            margin-bottom: 32px;
            font-size: 22px;
            line-height: 1.454545;
        }
    }

    .list-item__number-button {
        display: block;
        width: 56px;
        height: 56px;
        padding: 2px;
        border: 0;
        border-radius: 6px;
        background: rgba(255, 255, 255, 0.5);
        font-family: "Commuters Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 1;
        cursor: pointer;

        @media (max-width: 980px) {
            width: 48px;
            height: 48px;
        }

        &:hover {
            background: linear-gradient(90deg, #eaea15 2.23%, #ff855f 29.99%, #ff454e 51.12%, #0031ff 96.38%);
        }

        span {
            display: flex;
            height: 100%;
            justify-content: center;
            align-items: center;
            background-color: var(--klothed-bgColor);
            color: var(--klothed-textColor);
            border-radius: 6px;
        }
    }

    .is-active {
        .list-item__number-button {
            background: linear-gradient(90deg, #eaea15 2.23%, #ff855f 29.99%, #ff454e 51.12%, #0031ff 96.38%);
            box-decoration-break: clone;
            -webkit-box-decoration-break: clone;
        }
    }

    .list-item {
        position: relative;
        display: flex;
        gap: 24px;
        margin-bottom: 32px;

        @media (max-width: 980px) {
            display: block;
            margin-bottom: 20px;
        }
    }

    .list-item__number {
        flex: 0 0 56px;

        @media (max-width: 980px) {
            position: absolute;
            top: 0;
            left: 0;
            flex: 0 0 48px;
        }
    }

    .list-item__content {
        padding-top: 17px;
        flex: 1 1 100%;

        @media (max-width: 980px) {
            padding-top: 12px;
        }

        .button {
            width: 100%;
        }

        button {
            background: none;
            border: 0;
            border-radius: 0;
            cursor: pointer;
            color: var(--klothed-textColor);
            font-family: "Commuters Sans";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 1.5;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            text-align: left;

            @media (max-width: 980px) {
                margin-left: 57px;
                font-size: 14px;
                line-height: 1.428571;
            }

            strong {
                color: #ff855f;
                font-weight: normal;
            }

            span {
                display: block;

                & + span {
                    margin-top: 25px;
                }
            }
        }
    }

    .list-item__content-button {
        margin-top: 20px;

        @media (max-width: 980px) {
            margin-top: 25px;
        }
    }

    .auto-tab__left {
        position: relative;
        z-index: 2;
        flex: 0 0 35%;

        @media (max-width: 980px) {
            flex: 0 0 100%;
        }
    }

    .auto-tab__right {
        flex: 0 0 47.9%;
        padding-top: 52px;
        max-width: 575px;

        @media (max-width: 980px) {
            flex: 0 0 100%;
            max-width: 100%;
            order: -1;
            padding-top: 0;
            margin-bottom: 25px;
        }
    }

    .auto-tab__image {
        position: relative;
        padding-bottom: 100%;
        border: 1px solid rgba(255, 255, 255, 0.5);
        border-radius: 6px;

        @media (max-width: 980px) {
            border: 0;
            padding-bottom: 0;
        }
    }

    .auto-tab__laptop {
        position: absolute;
        bottom: 5.4%;
        right: -7%;
        width: 138.7%;

        @media (max-width: 980px) {
            position: relative;
            bottom: 0;
            right: 0;
            width: 100%;
            margin: 0 auto 15px;
            max-width: 794px;
        }

        img {
            max-width: 100%;
            height: auto;
        }

        .image {
            position: absolute;
            top: 6%;
            left: 13.3%;
            width: 72.4%;
            opacity: 0;
            transition: opacity 0.4s;
            z-index: 1;

            &.is-active {
                opacity: 1;
            }

            img {
                max-width: 100%;
                height: auto;
            }
        }
    }

    .additional-logo {
        position: absolute;
        right: 0;
        top: 0;
        width: 26.4%;
        border-radius: 6px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        transform: translate(90%, -75%);

        @media (max-width: 1300px) {
            transform: translate(49%, -95%);
        }

        @media (max-width: 980px) {
            transform: translate(90%, -75%);
        }

        img {
            display: block;
            max-width: 100%;
            height: auto;
        }
    }

    .additional-likes {
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(130%, -30%);

        @media (max-width: 980px) {
            transform: translate(50%, -60%);
        }

        path {
            opacity: 0;
        }

        .item2 {
            animation: opacityAnimateItem2 1.5s infinite;
        }

        .item3 {
            animation: opacityAnimateItem3 1.5s infinite;
        }

        .item4 {
            animation: opacityAnimateItem4 1.5s infinite;
        }
    }

    .additional-arrow {
        position: absolute;
        bottom: 9%;
        left: 100%;
    }

    .is-active {
        .additional-likes {
            path {
                opacity: 1;
            }
        }
    }

    .auto-tab__mobile {
        @media (max-width: 980px) {
            position: relative;
            max-width: 210px;
            margin: 0 auto;
            height: 420px;
        }

        .additional-likes {
            transform: translate(85%, -20%);
        }

        .image {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            opacity: 0;
            transition: opacity 0.4s;

            &.is-active {
                opacity: 1;
            }

            @media (max-width: 980px) {
                width: 100%;
            }

            img {
                max-width: 100%;
                height: auto;
            }
        }
    }
}

@keyframes opacityAnimateItem2 {
    0%,
    15% {
        opacity: 0;
    }

    25%,
    100% {
        opacity: 1;
    }
}

@keyframes opacityAnimateItem3 {
    0%,
    40% {
        opacity: 0;
    }

    50%,
    100% {
        opacity: 1;
    }
}

@keyframes opacityAnimateItem4 {
    0%,
    65% {
        opacity: 0;
    }

    75%,
    100% {
        opacity: 1;
    }
}
