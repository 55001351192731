.edits_home {
    display: flex;
    flex-direction: column;
    .edits_page {
        margin: 95px 0 auto 0;
        width: calc(100vw - (100vw - 100%));
    }

    .filter {
        margin-top: 95px;
    }

    .artifact_group {
        display: flex;
        margin: 95px auto 0 auto;
        min-height: calc(100vh - 380px);
        width: min(calc(100vw - 75px), 400px);
        flex-direction: column;
        font-family: Commuters Sans;
        align-self: center;
    }

    .edits_page {
        display: flex;
        justify-content: center;
        flex-direction: column;

        .style-count {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            /* identical to box height, or 117% */

            text-align: center;

            margin: 21px auto 0 auto;
            letter-spacing: 0.1em;

            color: var(--klothed-textColor);
        }

        .edits_title {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin: 0 24px 24px 24px;

            font-family: "Commuters Sans";
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 28px;
        }

        .edits_pageContent {
            display: flex;
            justify-content: flex-start;
            gap: 24px;
            padding-bottom: 8px;
            margin-left: 24px;
            margin-right: 24px;
            flex-flow: wrap;
        }
    }
}
