.about-fashion {
    .heading-line {
        margin-bottom: 64px;
    }

    .column-boxes-wrapper {
        @media (max-width: 980px) {
            margin-bottom: 52px;
        }
    }

    .column-boxes {
        .column-box {
            h3 {
                br {
                    @media (max-width: 980px) {
                        display: none;
                    }
                }
            }
        }
    }

    .column-boxes {
        @media (max-width: 980px) {
            margin-bottom: 25px;
        }

        &:after {
            @media (max-width: 980px) {
                display: none;
            }
        }

        h3 {
            @media (max-width: 767px) {
                margin-bottom: 0;
            }
        }

        .column-box__text {
            @media (max-width: 980px) {
                margin-top: 0;
            }

            &:after {
                @media (max-width: 980px) {
                    width: 1px;
                    height: 64px;
                    right: auto;
                    left: 0;
                    top: auto;
                    bottom: 100%;
                }
            }

            img {
                @media (max-width: 767px) {
                    max-width: 100%;
                    height: auto;
                }
            }
        }

        .button {
            @media (max-width: 980px) {
                margin-top: 32px;
            }
        }
    }
}
