.socials {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 8px 0;
    a {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
            width: auto;
            height: 28px;
        }
    }
}
