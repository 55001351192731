#root {
    min-height: 100vh;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

body {
    margin: 0;
    font-family: "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-height: 100vh;
    max-width: 100vw;
    background: var(--klothed-bgColor);
}
.klothed_page_v2 {
    font-family: "Commuters Sans";
    width: calc(100vw - (100vw - 100%));
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    overflow-x: hidden;
    color: var(--klothed-textColor);

    .spacer {
        display: flex;
        flex-grow: 1;
    }

    input,
    input:focus {
        outline: none;
        border: none;
    }

    a,
    a:visited,
    a:hover {
        color: var(--klothed-textColor);
        text-decoration: none;
    }

    .artifact_group {
        display: flex;
        margin-top: 95px;
        padding-left: 24px;
        padding-right: 24px;
        min-height: calc(100vh - 380px);
        width: calc(100vw - (100vw - 100%));
        align-self: center;
    }

    > div {
        margin-bottom: auto;
    }

    hr {
        width: 100%;
        margin: 0;
        padding: 0;
    }

    * {
        box-sizing: border-box;
    }

    @media (max-width: 1024px) {
        flex-wrap: wrap;
    }

    h4 {
        display: block;
        margin: 0 0 13px;
        text-align: center;
        font-size: 28px;
        font-weight: normal;
        font-family: "Sometimes Times";
        color: var(--klothed-textColor);
    }

    span {
        font-family: "Commuters Sans";
        font-weight: 400;
        color: var(--klothed-textColor);
        letter-spacing: 0.1em;
    }

    .orangeText {
        color: #ff855f;
        cursor: pointer;
        text-decoration: none;
    }

    .react-dropdown-select {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 145px;
        cursor: pointer;
        border-radius: 6px;

        .react-dropdown-select-dropdown-position-bottom {
            margin-top: -10px;
        }
        text-shadow: none;
        box-shadow: none;
        border-color: var(--klothed-textColor-trans50);
        &:hover {
            border-color: var(--klothed-textColor);
        }
        > .react-dropdown-select-content > span {
            font-family: "Commuters Sans";
            font-size: 11px;
            line-height: 16px;
            letter-spacing: 0.1em;
            font-weight: 400;
        }
        &[aria-expanded="true"] {
            border-color: var(--klothed-textColor-trans50);
            background-color: var(--klothed-textColor);
            color: var(--klothed-bgColor);
            border-radius: 6px 6px 0 0;
            > .react-dropdown-select-content {
                > span {
                    display: none;
                }
                &:after {
                    content: "What style?";
                    font-family: "Commuters Sans";
                    font-size: 11px;
                    line-height: 16px;
                    letter-spacing: 0.1em;
                    font-weight: 400;
                }
            }
        }
        input {
            display: none;
        }
        .react-dropdown-select-dropdown-handle {
            display: none;
        }
        div.react-dropdown-select-content {
            display: flex;
            align-items: center;
            justify-content: center;
            span {
                display: flex;
                align-items: center;
                justify-content: center;
            }
            span.react-dropdown-select-option {
                background-color: var(--klothed-bgColor);
            }
        }
        div.react-dropdown-select-dropdown {
            display: flex;
            width: 145px;
            border-radius: 0 0 8px 8px;
            span.react-dropdown-select-item {
                display: flex;
                align-items: center;
                justify-content: center;
                font-family: "Commuters Sans";
                font-size: 11px;
                line-height: 16px;
                letter-spacing: 0.1em;
                font-weight: 400;
                margin: 0;
                padding: 8px 4px 8px 4px;
                &:hover {
                    border-color: var(--klothed-textColor-trans50);
                    background-color: var(--klothed-textColor-trans20);
                }
            }
        }
        .react-dropdown-select-dropdown,
        .react-dropdown-select-item-selected {
            background: var(--klothed-bgColor);
        }
    }
}
